import { useFormik, FormikErrors } from "formik";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Layout from "../components/Layout";
import useApiData from "../hooks/useApiData";
import { post } from "../utils/Api";
import { Chips } from "primereact/chips";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { useLocation, useNavigate } from "react-router-dom";
import AcceptButton from "../components/AcceptButton";
import ValidationError from "../components/ValidationError";
import { get } from "../utils/Api";
import { isRole } from "../utils/Common";
import { Fieldset } from "primereact/fieldset";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import BeanInputOptional from "../components/BeanInputOptional";

type FocItemDetail = {
  sub_category_id: number;
  eg: string;
  product_id: number;
  quantity: number;
  freegift_no: number;
  api_key_id: number;
};

type FocItemDetailField = keyof FocItemDetail;

type AdvanceFocItemDetail = {
  sub_category_id: number;
  eg: string;
  product_id: number;
  quantity: number;
  freegift_no: number;
  api_key_id: number;
};

type AdvanceFocItemDetailField = keyof AdvanceFocItemDetail;

type AddOnFocItemDetail = {
  sub_category_id: number;
  eg: string;
  product_id: number;
  quantity: number;
  freegift_no: number;
  optional: number;
  api_key_id: number;
};

type AddOnFocItemDetailField = keyof AddOnFocItemDetail;

type OptionGroup = {
  add_on_name: string;
  cost_price: number;
  price: number;
  total_beans: number;
  item_details: {
    sub_category_id: number;
    eg: string;
    quantity: number;
    optional: number;
  }[];
  foc: {
    type: string;
    item_details: {
      sub_category_id: number;
      eg: string;
      product_id: number;
      quantity: number;
      freegift_no: number;
      optional: number;
    }[];
  };
};

const BundleComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.state.type;

  const [selectedFile, setSelectedFile] = useState("");
  const [selectedDetailFile, setSelectedDetailFile] = useState("");
  const [productLoading, setProductLoading] = useState(false);

  const sources = useApiData("/admin/get_sources");
  const sourcesData = sources.data as any;
  const sourcesList = sourcesData?.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });
  const category = useApiData("/admin/get_categories");
  const categoryData = category.data as any;
  const categoryList = categoryData?.data.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });

  const FILE_SIZE = 1024 * 1024 * 30; // 30MB
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [selectedCategoryAdvance, setSelectedCategoryAdvance] =
    useState<any>(null);

  const [freeGiftProductsListAdvance, setFreeGiftProductsListAdvance] =
    useState<any[]>([]);

  const [isOptionGroupVisible, setOptionGroupVisible] = useState(false);
  // Start: BASE PLAN FOC
  const [freeGiftProductsLists, setFreeGiftProductsLists] = useState<any[]>(
    [] as any,
  );

  const [freeGiftAddOnProductsList, setFreeGiftAddOnProductsList] = useState<
    any[]
  >([] as any);

  const subscriptionOptions = useApiData(`/product/get_subscription_options`);
  const subscriptionOptionData = subscriptionOptions.data as any;
  const subscriptionOptionList = subscriptionOptionData?.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });

  const onBeansOptionalChange = (index: number, checked: boolean) => {
    const updatedItemDetails = [
      ...formik_subscriptionBundle.values.base_plan.item_details,
    ];

    updatedItemDetails[index].optional = checked
      ? 1 - updatedItemDetails[index].optional
      : 0;

    formik_subscriptionBundle.setFieldValue(
      `base_plan.item_details[${index}].optional`,
      updatedItemDetails[index].optional,
    );

    formik_subscriptionBundle.setFieldValue(
      "base_plan.item_details",
      updatedItemDetails,
    );
  };

  const addFocItemGroup = () => {
    const newItem = {
      sub_category_id: 0,
      eg: "",
      product_id: null,
      quantity: 0,
      freegift_no: 0,
      optional: 0,
    };
    setFocItemDetails([...focItemDetails, newItem]);

    // Check if `foc` object exists in Formik state
    if (formik_subscriptionBundle.values.base_plan.foc) {
      // If `foc` object exists, update `item_details`
      formik_subscriptionBundle.setFieldValue(`base_plan.foc.item_details`, [
        ...formik_subscriptionBundle.values.base_plan.foc.item_details,
        newItem,
      ]);
    } else {
      // If `foc` object doesn't exist, create it with the new `item_details`
      formik_subscriptionBundle.setFieldValue(`base_plan.foc`, {
        item_details: [newItem],
      });
    }
  };

  const removeFocItemGroup = (index: number) => {
    const updatedGroups = [...focItemDetails];
    updatedGroups.splice(index, 1);
    setFocItemDetails(updatedGroups);

    // Update Formik values
    const updatedFormikValues = [
      ...formik_subscriptionBundle.values.base_plan.foc.item_details,
    ];
    updatedFormikValues.splice(index, 1);

    // If the length of updatedFormikValues is 0, remove the whole foc object
    if (updatedFormikValues.length === 0) {
      formik_subscriptionBundle.setFieldValue("base_plan.foc", undefined);
    } else {
      formik_subscriptionBundle.setFieldValue(
        `base_plan.foc.item_details`,
        updatedFormikValues,
      );
    }
  };

  const handleInputChangeFocItem = (
    index: number,
    field: FocItemDetailField,
    value: any,
  ) => {
    const updatedFocItemDetails = [...focItemDetails];
    (updatedFocItemDetails[index] as any)[field] = value;
    setFocItemDetails(updatedFocItemDetails);
    formik_subscriptionBundle.setFieldValue(
      `base_plan.foc.item_details[${index}].${field}`,
      value,
    );
  };

  const calculateTotalRequiredBeans = (itemDetails: any) => {
    let uncheckedTotal = 0;
    let maxCheckedQuantity = 0;
    for (const item of itemDetails) {
      if (item.optional === 1) {
        if (item.quantity > maxCheckedQuantity) {
          maxCheckedQuantity = item.quantity;
        }
      } else {
        uncheckedTotal += item.quantity;
      }
    }
    const totalBeans = uncheckedTotal + maxCheckedQuantity;
    return totalBeans;
  };

  const calculateTotalAddOnRequiredBeans = (itemDetails: any) => {
    let uncheckedTotal = 0;
    let maxCheckedQuantity = 0;
    for (const item of itemDetails) {
      if (item.optional === 1) {
        if (item.quantity > maxCheckedQuantity) {
          maxCheckedQuantity = item.quantity;
        }
      } else {
        uncheckedTotal += item.quantity;
      }
    }
    const totalBeans = uncheckedTotal + maxCheckedQuantity;
    return totalBeans;
  };

  const getProductList = useCallback(async (index: any) => {
    try {
      const response = await get(`/admin/get_all_products`);
      if (response?.data && response?.success) {
        const product = response.data.map((item: any) => ({
          label: item.name,
          value: item.id,
          image_url: item.image_url,
          sub_category_id: item.sub_category_id,
        }));
        setFreeGiftProductsLists((prevLists) => {
          const updatedLists = [...prevLists];
          updatedLists[index] = product;
          return updatedLists;
        });
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  }, []);

  const getProductCategory = useCallback(async (index: any) => {
    try {
      const response = await get(`/admin/get_category_by_id/2`);
      if (response?.data && response?.success) {
        const category = response.data.sub_category
          .filter(
            (item: any) =>
              item.name !== "Roadshow Promotion" &&
              item.name !== "Roadshow Promo",
          )
          .map((item: any) => ({
            label: item.name,
            value: item.id,
            sub_category_id: item.id,
          }));
        setFreeGiftProductsLists((prevLists) => {
          const updatedLists = [...prevLists];
          updatedLists[index] = category;
          return updatedLists;
        });
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  }, []);

  // Start: ADD ON ADVANCE

  const addFocAdvanceItemGroup = () => {
    const newItem = {
      sub_category_id: 0,
      eg: "",
      product_id: null,
      quantity: 0,
      freegift_no: 0,
      optional: 0,
    };
    setFocAdvanceItemDetails([...focAdvanceItemDetails, newItem]);

    // Check if `advance_foc` object exists in Formik state
    if (formik_subscriptionBundle.values.base_plan.advance_foc) {
      // If `advance_foc` object exists, update `item_details`
      formik_subscriptionBundle.setFieldValue(
        `base_plan.advance_foc.item_details`,
        [
          ...formik_subscriptionBundle.values.base_plan.advance_foc
            .item_details,
          newItem,
        ],
      );
    } else {
      // If `advance_foc` object doesn't exist, create it with the new `item_details`
      formik_subscriptionBundle.setFieldValue(`base_plan.advance_foc`, {
        item_details: [newItem],
        minimum_bundle_purchase_qty: 0,
      });
    }
  };

  const removeFocAdvanceItemGroup = (index: number) => {
    const updatedGroups = [...focAdvanceItemDetails];
    updatedGroups.splice(index, 1);
    setFocAdvanceItemDetails(updatedGroups);

    // Update Formik values
    const updatedFormikValues = [
      ...formik_subscriptionBundle.values.base_plan.advance_foc.item_details,
    ];
    updatedFormikValues.splice(index, 1);

    // If the length of updatedFormikValues is 0, remove the whole `advance_foc` object
    if (updatedFormikValues.length === 0) {
      formik_subscriptionBundle.setFieldValue(
        "base_plan.advance_foc",
        undefined,
      );
    } else {
      formik_subscriptionBundle.setFieldValue(
        `base_plan.advance_foc.item_details`,
        updatedFormikValues,
      );
    }
  };

  const handleInputChangeFocAdvanceItem = (
    index: number,
    field: AdvanceFocItemDetailField,
    value: any,
  ) => {
    const updatedAdvanceFocItemDetails = [...focAdvanceItemDetails];
    (updatedAdvanceFocItemDetails[index] as any)[field] = value;
    setFocAdvanceItemDetails(updatedAdvanceFocItemDetails);
    // Update Formik values
    formik_subscriptionBundle.setFieldValue(
      `base_plan.advance_foc.item_details[${index}].${field}`,
      value,
    );
  };

  const getAdvanceProductList = useCallback(async (index: any) => {
    try {
      const response = await get(`/admin/get_all_products`);
      if (response?.data && response?.success) {
        const product = response.data.map((item: any) => ({
          label: item.name,
          value: item.id,
          image_url: item.image_url,
          sub_category_id: item.sub_category_id,
        }));

        // Update the specific product list based on the index
        setFreeGiftProductsListAdvance((prevLists) => {
          const updatedLists = [...prevLists];
          updatedLists[index] = product;
          return updatedLists;
        });
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }

    return []; // Return an empty array as a fallback
  }, []);

  const getAdvanceProductCategory = useCallback(async (index: any) => {
    try {
      const response = await get(`/admin/get_category_by_id/2`);
      if (response?.data && response?.success) {
        const category = response.data.sub_category
          .filter(
            (item: any) =>
              item.name !== "Roadshow Promotion" &&
              item.name !== "Roadshow Promo",
          )
          .map((item: any) => ({
            label: item.name,
            value: item.id,
            sub_category_id: item.id,
          }));

        // Update the specific product list based on the index
        setFreeGiftProductsListAdvance((prevLists) => {
          const updatedLists = [...prevLists];
          updatedLists[index] = category;
          return updatedLists;
        });
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }

    return []; // Return an empty array as a fallback
  }, []);

  // End: ADD ON ADVANCE

  // End: BASE PLAN

  // Start: ADD ON GROUP
  const [optionGroups, setOptionGroups] = useState<OptionGroup[]>([]);

  const addOptionGroup = () => {
    const newGroup = {
      add_on_name: "",
      cost_price: 0,
      price: 0,
      total_beans: 0,
      item_details: [
        { sub_category_id: 2, eg: "Premium", quantity: 0, optional: 0 },
        { sub_category_id: 1, eg: "Signature", quantity: 0, optional: 0 },
        { sub_category_id: 4, eg: "Standard", quantity: 0, optional: 0 },
        { sub_category_id: 3, eg: "Blended", quantity: 0, optional: 0 },
      ],
      foc: {
        type: "",
        item_details: [], // Start with an empty array
      },
    };

    // Update local state and Formik state as before
    setOptionGroups((prevGroups) => [...prevGroups, newGroup]);
    const currentAddOnPlans =
      formik_subscriptionBundle.values.add_on_plans ?? [];
    formik_subscriptionBundle.setFieldValue("add_on_plans", [
      ...currentAddOnPlans,
      newGroup,
    ]);
  };

  const removeOptionGroup = (index: number) => {
    const updatedGroups = [...optionGroups];
    updatedGroups.splice(index, 1);
    setOptionGroups(updatedGroups);

    // Update formik_subscriptionBundle's add_on_plans
    const updatedPlans = [...formik_subscriptionBundle.values.add_on_plans];
    updatedPlans.splice(index, 1);
    formik_subscriptionBundle.setFieldValue("add_on_plans", updatedPlans);
  };

  const [beansAddOnOptional, setBeansAddOnOptional] = useState<any[]>(
    optionGroups.map(() => []),
  );

  const onBeansAddOnOptionalChange = (
    planIndex: number,
    itemIndex: number,
    checked: boolean,
  ) => {
    const updatedAddOnPlans = [
      ...formik_subscriptionBundle.values.add_on_plans,
    ];
    const updatedItemDetails = updatedAddOnPlans[planIndex].item_details;

    updatedItemDetails[itemIndex].optional = checked
      ? 1 - updatedItemDetails[itemIndex].optional
      : 0;

    formik_subscriptionBundle.setFieldValue(
      `add_on_plans[${planIndex}].item_details[${itemIndex}].optional`,
      updatedItemDetails[itemIndex].optional,
    );

    formik_subscriptionBundle.setFieldValue(
      `add_on_plans[${planIndex}].item_details`,
      updatedItemDetails,
    );
  };

  const addFocAddOnItemGroup = (planIndex: number) => {
    const newItem = {
      sub_category_id: 0,
      eg: "",
      product_id: null,
      quantity: 0,
      freegift_no: 0,
      optional: 0,
    };

    // Update local state
    setFocAddOnItemDetails((prevDetails: any) => {
      const updatedDetails = { ...prevDetails };
      updatedDetails[planIndex] = updatedDetails[planIndex] ?? [];
      updatedDetails[planIndex].push(newItem);
      return updatedDetails;
    });

    // Safely access existing foc item_details or default to an empty array
    const existingFocItemDetails =
      formik_subscriptionBundle.values.add_on_plans[planIndex]?.foc
        ?.item_details ?? [];

    // Construct the updated foc item_details with the new item added
    const updatedFormikValues = [...existingFocItemDetails, newItem];

    // Update Formik values
    formik_subscriptionBundle.setFieldValue(
      `add_on_plans[${planIndex}].foc.item_details`,
      updatedFormikValues,
    );
  };

  const removeFocAddOnItemGroup = (planIndex: number, index: number) => {
    const updatedDetails = { ...focAddOnItemDetails };
    if (updatedDetails[planIndex]) {
      updatedDetails[planIndex].splice(index, 1);
      setFocAddOnItemDetails(updatedDetails);

      // Update Formik values
      const updatedFormikValues = [
        ...formik_subscriptionBundle.values.add_on_plans[planIndex].foc
          .item_details,
      ];
      updatedFormikValues.splice(index, 1);
      formik_subscriptionBundle.setFieldValue(
        `add_on_plans[${planIndex}].foc.item_details`,
        updatedFormikValues,
      );
    }
  };

  const handleInputChangeFocAddOnItem = (
    planIndex: number,
    index: number, // This is the index of the item within the plan's array
    field: AddOnFocItemDetailField,
    value: any,
  ) => {
    const updatedAddOnFocItemDetails = { ...focAddOnItemDetails };

    if (!updatedAddOnFocItemDetails[planIndex]) {
      updatedAddOnFocItemDetails[planIndex] = [];
    }

    if (!updatedAddOnFocItemDetails[planIndex][index]) {
      updatedAddOnFocItemDetails[planIndex][index] = {};
    }

    (updatedAddOnFocItemDetails[planIndex][index] as any)[field] = value;

    setFocAddOnItemDetails(updatedAddOnFocItemDetails);
    formik_subscriptionBundle.setFieldValue(
      `add_on_plans[${planIndex}].foc.item_details[${index}].${field}`,
      value,
    );
  };

  const getAddOnProductList = useCallback(
    async (planIndex: any, itemIndex: any) => {
      try {
        const response = await get(`/admin/get_all_products`);
        if (response?.data && response?.success) {
          const product = response.data.map((item: any) => ({
            label: item.name,
            value: item.id,
            image_url: item.image_url,
            sub_category_id: item.sub_category_id,
          }));

          // Update the specific product list based on the index
          setFreeGiftAddOnProductsList((prevLists) => {
            const updatedLists = { ...prevLists };
            if (!updatedLists[planIndex]) {
              updatedLists[planIndex] = [];
            }
            updatedLists[planIndex][itemIndex] = product;
            return updatedLists;
          });
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }

      return []; // Return an empty array as a fallback
    },
    [],
  );

  const getAddOnProductCategory = useCallback(
    async (planIndex: any, itemIndex: any) => {
      try {
        const response = await get(`/admin/get_category_by_id/2`);
        if (response?.data && response?.success) {
          const category = response.data.sub_category
            .filter(
              (item: any) =>
                item.name !== "Roadshow Promotion" &&
                item.name !== "Roadshow Promo",
            )
            .map((item: any) => ({
              label: item.name,
              value: item.id,
              sub_category_id: item.id,
            }));

          // Update the specific product list based on the index
          setFreeGiftAddOnProductsList((prevLists) => {
            const updatedLists = { ...prevLists };
            if (!updatedLists[planIndex]) {
              updatedLists[planIndex] = [];
            }
            updatedLists[planIndex][itemIndex] = category;
            return updatedLists;
          });
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }

      return []; // Return an empty array as a fallback
    },
    [],
  );
  // End: ADD ON GROUP

  const formikValueBundle = location.state
    ? location.state.formikValueBundle
    : null;
  useEffect(() => {
    if (formikValueBundle && formikValueBundle.image_url) {
      setSelectedFile(formikValueBundle.image_url);
    }
    if (formikValueBundle && formikValueBundle.detail_image_url) {
      setSelectedDetailFile(formikValueBundle.detail_image_url);
    }
  }, [formikValueBundle]);

  const [focItemDetails, setFocItemDetails] = useState(
    formikValueBundle &&
      formikValueBundle.base_plan.foc && // Check if foc exists
      formikValueBundle.base_plan.foc.item_details &&
      formikValueBundle.base_plan.foc.item_details.length > 0
      ? formikValueBundle.base_plan.foc.item_details.map((item: any) => ({
          ...item,
          // Ensure product_id and sub_category_id have default values if not present
          product_id: item.product_id || 0,
          sub_category_id: item.sub_category_id || 0,
          freegift_no: item.freegift_no || 0,
          api_key_id: item.api_key_id || 0,
        }))
      : [],
  );

  const [focAdvanceItemDetails, setFocAdvanceItemDetails] = useState(
    formikValueBundle &&
      formikValueBundle.base_plan.advance_foc && // Check if advance_foc exists
      formikValueBundle.base_plan.advance_foc.item_details &&
      formikValueBundle.base_plan.advance_foc.item_details.length > 0
      ? formikValueBundle.base_plan.advance_foc.item_details.map(
          (item: any) => ({
            ...item,
            // Ensure product_id and sub_category_id have default values if not present
            product_id: item.product_id || 0,
            sub_category_id: item.sub_category_id || 0,
            freegift_no: item.freegift_no || 0,
            api_key_id: item.api_key_id || 0,
          }),
        )
      : [],
  );

  const [focAddOnItemDetails, setFocAddOnItemDetails] = useState(() => {
    if (
      formikValueBundle &&
      formikValueBundle.add_on_plans &&
      formikValueBundle.add_on_plans.length > 0
    ) {
      return formikValueBundle.add_on_plans.reduce(
        (acc: any, plan: any, index: any) => {
          if (
            plan.foc &&
            plan.foc.item_details &&
            plan.foc.item_details.length > 0
          ) {
            acc[index] = plan.foc.item_details.map((item: any) => ({
              ...item,
              product_id: item.product_id || 0,
              sub_category_id: item.sub_category_id || 0,
              api_key_id: item.api_key_id || 0,
            }));
          } else {
            acc[index] = [];
          }
          return acc;
        },
        {},
      );
    } else {
      return {};
    }
  });

  const initialCategoryValues = Array.from(
    { length: focItemDetails.length },
    () => 0,
  ); // Initialize with 0 for all groups

  const [selectedCategories, setSelectedCategories] = useState(
    formikValueBundle?.base_plan?.foc?.item_details &&
      formikValueBundle?.base_plan?.foc?.item_details?.length
      ? formikValueBundle?.base_plan?.foc?.item_details?.map((item: any) =>
          item.product_id !== 0 ? 1 : 2,
        )
      : initialCategoryValues,
  );

  const initialAdvanceCategoryValues = Array.from(
    { length: focAdvanceItemDetails.length },
    () => 0,
  ); // Initialize with 0 for all groups

  const [selectedAdvanceCategories, setSelectedAdvanceCategories] = useState(
    formikValueBundle?.base_plan?.advance_foc?.item_details &&
      formikValueBundle?.base_plan?.advance_foc?.item_details?.length
      ? formikValueBundle?.base_plan?.advance_foc?.item_details?.map(
          (item: any) => (item.product_id !== 0 ? 1 : 2),
        )
      : initialAdvanceCategoryValues,
  );

  const initialAddOnCategoryValues = Array.from(
    { length: focAddOnItemDetails.length },
    () => 0,
  ); // Initialize with 0 for all groups

  const [selectedAddOnCategories, setSelectedAddOnCategories] = useState(
    formikValueBundle?.add_on_plans && formikValueBundle?.add_on_plans?.length
      ? formikValueBundle?.add_on_plans?.map((plan: any) =>
          plan?.foc?.item_details?.map((item: any) =>
            item.product_id !== 0 ? 1 : 2,
          ),
        )
      : initialAddOnCategoryValues,
  );

  const initialProductValues = Array.from(
    { length: focItemDetails.length },
    () => null,
  ); // Initialize with null for all groups

  const [selectedProducts, setSelectedProducts] =
    useState(initialProductValues);

  const initialAdvanceProductValues = Array.from(
    { length: focAdvanceItemDetails.length },
    () => null,
  ); // Initialize with null for all groups

  const [selectedAdvanceProducts, setSelectedAdvanceProducts] = useState(
    initialAdvanceProductValues,
  );

  const initialAddOnProductValues = Array.from(
    { length: focAddOnItemDetails.length },
    () => null,
  ); // Initialize with null for all groups

  const [selectedAddOnProducts, setSelectedAddOnProducts] = useState(
    initialAddOnProductValues,
  );

  const initialValuesBundle = {
    id: 0,
    image_url: "",
    detail_image_url: "",
    status: true,
    plan_name: "",
    sku: "",
    subscription_option_id: "",
    stock: "",
    category_id: 0,
    sub_category_id: 0,
    // api_key_id: 0,
    base_plan: {
      cost_price: 0,
      price: 0,
      total_beans: 0,
      item_details: [
        {
          sub_category_id: 2,
          eg: "Premium",
          quantity: 0,
          optional: 0,
        },
        {
          sub_category_id: 1,
          eg: "Signature",
          quantity: 0,
          optional: 0,
        },
        {
          sub_category_id: 4,
          eg: "Standard",
          quantity: 0,
          optional: 0,
        },
        {
          sub_category_id: 3,
          eg: "Blended",
          quantity: 0,
          optional: 0,
        },
      ],
      foc: {
        type: "",
        item_details: focItemDetails,
      },
      advance_foc: {
        type: "",
        minimum_bundle_purchase_qty: 0,
        item_details: focAdvanceItemDetails,
      },
    },
    add_on_plans: [],
  };

  interface SubscriptionBundle {
    id: number;
    image_url: string;
    detail_image_url: string;
    status: boolean;
    plan_name: string;
    sku: string;
    subscription_option_id: string;
    stock: string;
    category_id: number;
    sub_category_id: number;
    // api_key_id: number;
    base_plan: {
      cost_price: number;
      price: number;
      total_beans: number;
      item_details: {
        sub_category_id: number;
        eg: string;
        quantity: number;
        optional: number;
      }[];
      foc: {
        type: string;
        item_details: {
          sub_category_id: number;
          product_id: number;
          quantity: number;
          freegift_no: number;
          optional: number;
          api_key_id: number;
        }[];
      };
      advance_foc: {
        type: string;
        minimum_bundle_purchase_qty: number;
        item_details: {
          sub_category_id: number;
          product_id: number;
          quantity: number;
          freegift_no: number;
          optional: number;
          api_key_id: number;
        }[];
      };
    };
    add_on_plans: {
      add_on_name: string;
      cost_price: number;
      price: number;
      total_beans: number;
      item_details: {
        sub_category_id: number;
        eg: string;
        quantity: number;
        optional: number;
      }[];
      foc: {
        type: string;
        item_details: {
          sub_category_id: number;
          product_id: number;
          quantity: number;
          freegift_no: number;
          optional: number;
          api_key_id: number;
        }[];
      };
    }[];
  }

  type SubCategoryID = 1 | 2 | 3 | 4;

  const subCategoryIdToLabel: Record<SubCategoryID, string> = {
    2: "Premium",
    1: "Signature",
    4: "Standard",
    3: "Blended",
  };

  const formik_subscriptionBundle = useFormik<SubscriptionBundle>({
    initialValues: formikValueBundle || initialValuesBundle,
    validationSchema: Yup.object({
      plan_name: Yup.string().required("Plan name is required"),
      sku: Yup.string().required("SKU is required"),
      subscription_option_id: Yup.number().required(
        "Subscription Option Id is required",
      ),
      //   api_key_id: Yup.number().required("Source is required"),
      stock: Yup.string().when("id", (id, schema) => {
        if (+id === 0)
          return schema
            .matches(/^\d*$/, "Stock is not valid")
            .required("Stock is required");
        return schema;
      }),
      base_plan: Yup.object().shape({
        cost_price: Yup.number()
          .test(
            "is-greater-than-zero",
            "Cost Price must be greater than 0",
            (value) => value !== undefined && value > 0,
          )
          .required("Cost Price is required"),
        price: Yup.number()
          .test(
            "is-greater-than-zero",
            "Price must be greater than 0",
            (value) => value !== undefined && value > 0,
          )
          .required("Price is required"),
        total_beans: Yup.number()
          .test(
            "is-valid-total-beans",
            "Total Beans must be greater than 0",
            function (value) {
              const { item_details } = this.parent; // Get the item_details array
              const totalBeans = calculateTotalRequiredBeans(item_details);
              return totalBeans > 0;
            },
          )
          .required("Total Beans is required"),
        foc: Yup.object().shape({
          item_details: Yup.array().of(
            Yup.object().shape({
              api_key_id: Yup.number().required("Source is required"),
              sub_category_id: Yup.number()
                .required("Sub Category ID is required")
                .notOneOf([0], "Sub Category is required"),
              product_id: Yup.number()
                .required("Product ID is required")
                .test(
                  "is-not-null",
                  "Product is required",
                  (value) => value !== null,
                ),
              quantity: Yup.number()
                .required("Quantity is required")
                .min(1, "Quantity must be greater than 0"),
              freegift_no: Yup.number()
                .required("Free Gift number is required")
                .min(1, "Free Gift number must be greater than 0"),
            }),
          ),
        }),
        advance_foc: Yup.object().shape({
          minimum_bundle_purchase_qty: Yup.number().test(
            "conditional-requirement",
            "Minimum Bundle Purchase Quantity is required",
            function (value) {
              const { item_details } = this.parent;
              if (item_details && item_details.length > 0) {
                return value !== undefined && value >= 1;
              }
              return true; // If there are no item_details, the field is not required
            },
          ),
          item_details: Yup.array().of(
            Yup.object().shape({
              sub_category_id: Yup.number()
                .required("Sub Category ID is required")
                .notOneOf([0], "Sub Category is required"),
              product_id: Yup.number()
                .required("Product ID is required")
                .test(
                  "is-not-null",
                  "Product is required",
                  (value) => value !== null,
                ),
              quantity: Yup.number()
                .required("Quantity is required")
                .min(1, "Quantity must be greater than 0"),
              freegift_no: Yup.number()
                .required("Free Gift number is required")
                .min(1, "Free Gift number must be greater than 0"),
            }),
          ),
        }),
      }),
      add_on_plans: Yup.array().of(
        Yup.object().shape({
          add_on_name: Yup.string().required("Add On Name is required"),
          cost_price: Yup.number().required("Cost Price is required"),
          price: Yup.number().required("Price is required"),
          total_beans: Yup.number()
            .test(
              "is-valid-total-beans",
              "Total Beans must be greater than 0",
              function (value) {
                const { item_details } = this.parent; // Get the item_details array
                const totalBeans =
                  calculateTotalAddOnRequiredBeans(item_details);
                return totalBeans > 0;
              },
            )
            .required("Total Beans is required"),
          foc: Yup.object().shape({
            item_details: Yup.array()
              .of(
                Yup.object().shape({
                  api_key_id: Yup.number().required("Source is required"),
                  sub_category_id: Yup.number()
                    .required("Sub Category ID is required")
                    .notOneOf([0], "Sub Category is required"),
                  product_id: Yup.number()
                    .required("Product ID is required")
                    .test(
                      "is-not-null",
                      "Product is required",
                      (value) => value !== null,
                    ),
                  quantity: Yup.number()
                    .required("Quantity is required")
                    .min(1, "Quantity must be greater than 0"),
                  freegift_no: Yup.number()
                    .required("Free Gift number is required")
                    .min(1, "Free Gift number must be greater than 0"),
                }),
              )
              .test(
                "unique-sub-category-validation",
                "Please select another product as the same category is being choosen",
                function (item) {
                  const itemDetails = item || [];

                  const specialSubCategoryIds = [1, 2, 3, 4];

                  const specialItemsWithProductId = itemDetails.filter(
                    (item: any) =>
                      specialSubCategoryIds.includes(item.sub_category_id) &&
                      item.product_id !== 0,
                  );

                  if (specialItemsWithProductId.length === 0) return true;

                  const uniqueSubCategoryIds = new Set(
                    specialItemsWithProductId.map(
                      (item: any) => item.sub_category_id,
                    ),
                  );

                  const invalidItemsExist = itemDetails.some(
                    (item: any) =>
                      !item.product_id &&
                      uniqueSubCategoryIds.has(item.sub_category_id),
                  );

                  return !invalidItemsExist;
                },
              ),
          }),
        }),
      ),
    }),
    validateOnChange: true,
    onSubmit: async (values) => {
      const {
        id,
        image_url,
        detail_image_url,
        plan_name,
        sku,
        // api_key_id,
        stock,
        status,
        sub_category_id,
        subscription_option_id,
        base_plan: { cost_price, price, item_details, advance_foc },
      } = values;
      console.log(values);
      const file: any = image_url;
      const detail_file: any = detail_image_url;
      // Calculate the total beans
      const total_beans = calculateTotalRequiredBeans(item_details);

      // Create the 'base_plan' object
      console.log("focItemDetails", focItemDetails);
      const base_plan = {
        cost_price,
        price,
        total_beans,
        item_details: item_details.map((item: any) => ({
          sub_category_id: item.sub_category_id,
          quantity: item.quantity,
          optional: item.optional,
        })),
        // Conditionally include 'foc' property
        ...(focItemDetails.length > 0 && {
          foc: {
            type: "base_plan_foc",
            item_details: focItemDetails.map((item: any) => ({
              sub_category_id: item.sub_category_id,
              product_id: item.product_id,
              quantity: item.quantity,
              optional: item.optional,
              freegift_no: item.freegift_no,
              api_key_id: item.api_key_id,
            })),
          },
        }),
        // Conditionally include 'advance_foc' property
        ...(focAdvanceItemDetails.length > 0 && {
          advance_foc: {
            type: "advance_foc",
            minimum_bundle_purchase_qty:
              advance_foc.minimum_bundle_purchase_qty,
            item_details: focAdvanceItemDetails.map((item: any) => ({
              sub_category_id: item.sub_category_id,
              product_id: item.product_id,
              quantity: item.quantity,
              optional: item.optional,
              freegift_no: item.freegift_no,
              api_key_id: item.api_key_id,
            })),
          },
        }),
      };

      console.log("base plan", base_plan);

      // Process the add_on_plans
      const add_on_plans = formik_subscriptionBundle.values.add_on_plans?.map(
        (group: any, index: any) => {
          const total_beans_add_on = calculateTotalAddOnRequiredBeans(
            group.item_details,
          );

          return {
            add_on_name: group.add_on_name,
            cost_price: group.cost_price,
            price: group.price,
            total_beans: total_beans_add_on,
            item_details: group.item_details.map((item: any) => ({
              sub_category_id: item.sub_category_id,
              quantity: item.quantity,
              optional: item.optional,
            })),
            // Conditionally include 'foc' property
            ...(focAddOnItemDetails[index] &&
              focAddOnItemDetails[index].length > 0 && {
                foc: {
                  type: "add_on_plan_foc",
                  item_details: focAddOnItemDetails[index].map((item: any) => ({
                    sub_category_id: item.sub_category_id,
                    product_id: item.product_id,
                    quantity: item.quantity,
                    optional: item.optional,
                    freegift_no: item.freegift_no,
                    api_key_id: item.api_key_id,
                  })),
                },
              }),
          };
        },
      );

      try {
        setProductLoading(true);
        const formData = new FormData();
        if (id > 0) {
          formData.append("id", id.toString());
        }

        // if (typeof file === "object" && file !== null) {
        formData.append("image_url", file);
        // }
        // if (typeof detail_file === "object" && detail_file !== null) {
        formData.append("detail_image_url", detail_file);
        // }
        formData.append("category_id", "3");
        formData.append("plan_name", plan_name);
        formData.append("stock", stock);
        // Append the 'basePlan' object
        formData.append("base_plan", JSON.stringify(base_plan));
        if (add_on_plans && add_on_plans.length > 0) {
          // Append the add-on plans
          formData.append("add_on_plans", JSON.stringify(add_on_plans));
        }

        formData.append("sku", sku);
        // formData.append("api_key_id", api_key_id as unknown as string);
        formData.append("status", status ? "1" : "0");
        formData.append("subscription_option_id", subscription_option_id);
        console.log("important datta", formData);
        const response = await post("/admin/save_product_bundle", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setProductLoading(false);

        if (response?.success) {
          toast.success(response.message);
          setSelectedFile("");
          setSelectedDetailFile("");
          formik_subscriptionBundle.resetForm();
          navigate("/inventory");
        }
      } catch (error: any) {
        setProductLoading(false);

        if (
          error.response?.data?.message &&
          typeof error.response.data.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  console.log(formik_subscriptionBundle.values);
  console.log(formik_subscriptionBundle.errors);

  // End: Bundle

  //   const subCategory = useApiData(
  //     `/admin/get_category_by_id/${formik_inventory.values.category_id || null}`,
  //   );
  //   const subCategoryData = subCategory.data as any;
  //   const subCategoryList = subCategoryData?.sub_category?.map((item: any) => {
  //     return { label: item?.name, value: item?.id };
  //   });

  const roastDetail = useApiData(`/admin/get_roasts`);
  const roastData = roastDetail.data as any;
  const roastList = roastData?.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });

  const regionDetail = useApiData(`/admin/get_regions`);
  const regionData = regionDetail.data as any;
  const regionList = regionData?.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });

  const onFileUpload = async (event: any, type: string) => {
    const file = event?.target?.files[0];
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const base64data = reader.result;
        if (type === "product") {
          setSelectedFile(base64data as string);
        } else if (type === "detail") {
          setSelectedDetailFile(base64data as string);
        }
      };
    }
  };

  const hasMountedFoc = useRef(false);
  const hasMountedAdvanceFoc = useRef(false);
  const hasMountedAddOnFoc = useRef(false);

  useEffect(() => {
    if (!hasMountedFoc.current) {
      focItemDetails.forEach((item: any, index: any) => {
        if (selectedCategories[index] === 1) {
          getProductList(index);
        } else if (selectedCategories[index] === 2) {
          getProductCategory(index);
        }
        hasMountedFoc.current = true;
      });
    }
  }, [focItemDetails, selectedCategories, getProductList, getProductCategory]);

  useEffect(() => {
    if (!hasMountedAdvanceFoc.current) {
      focAdvanceItemDetails.forEach((item: any, index: any) => {
        if (selectedAdvanceCategories[index] === 1) {
          getAdvanceProductList(index);
        } else if (selectedAdvanceCategories[index] === 2) {
          getAdvanceProductCategory(index);
        }
        hasMountedAdvanceFoc.current = true;
      });
    }
  }, [
    focAdvanceItemDetails,
    selectedAdvanceCategories,
    getAdvanceProductList,
    getAdvanceProductCategory,
  ]);

  useEffect(() => {
    if (
      !hasMountedAddOnFoc.current &&
      focAddOnItemDetails &&
      selectedAddOnCategories
    ) {
      Object.entries(focAddOnItemDetails).forEach(([planIndex, items]) => {
        if (items) {
          (items as Array<any>).forEach((item: any, itemIndex: any) => {
            if (
              selectedAddOnCategories[planIndex] &&
              selectedAddOnCategories[planIndex][itemIndex] === 1
            ) {
              getAddOnProductList(planIndex, itemIndex);
            } else if (
              selectedAddOnCategories[planIndex] &&
              selectedAddOnCategories[planIndex][itemIndex] === 2
            ) {
              getAddOnProductCategory(planIndex, itemIndex);
            }
          });
        }
      });
      hasMountedAddOnFoc.current = true;
    }
  }, [
    focAddOnItemDetails,
    selectedAddOnCategories,
    getAddOnProductList,
    getAddOnProductCategory,
  ]);

  const freeGiftCategoryList = [
    {
      label: "Product",
      value: 1,
    },
    {
      label: "Category",
      value: 2,
    },
  ];

  return (
    <div className="card-cfg !shadow-none">
      <form onSubmit={formik_subscriptionBundle.handleSubmit}>
        <div className="flex md:justify-start md:flex-row flex-col gap-4">
          <div className="bg-[#f7f3f3] text-center p-4 rounded-[7px]">
            <div
              className={`w-[128px] h-[128px] border border-solid rounded-[6px] bg-[#FFFFFF] m-auto flex justify-center items-center relative ${
                formik_subscriptionBundle.touched.image_url &&
                formik_subscriptionBundle.errors.image_url
                  ? "border-[#f44336]"
                  : "border-[#ACACAC]"
              }`}
            >
              {selectedFile || formik_subscriptionBundle.values.image_url ? (
                <div className="w-full h-full relative">
                  <img
                    src={
                      selectedFile || formik_subscriptionBundle.values.image_url
                    }
                    onError={() =>
                      setSelectedFile(`${process.env.PUBLIC_URL}/no-image.png`)
                    }
                    alt="Preview"
                    className="m-[auto] w-full h-full object-cover rounded-[6px] p-[10px]"
                  />
                  <button
                    className="absolute top-[-10px] right-[-10px] rounded-[50%] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3]"
                    onClick={() => {
                      formik_subscriptionBundle.setFieldValue("image_url", "");
                      setSelectedFile("");
                    }}
                  >
                    <img
                      src={require("../assets/images/icon-close.png")}
                      alt="Remove icon"
                      className="w-[25px]"
                    />
                  </button>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="image_url"
                    name="image_url"
                    hidden
                    onChange={(e: any) => {
                      onFileUpload(e, "product");
                      formik_subscriptionBundle.setFieldValue(
                        "image_url",
                        e?.target?.files[0],
                      );
                      setSelectedFile(URL.createObjectURL(e?.target?.files[0]));
                    }}
                  />
                  <label
                    htmlFor="image_url"
                    className="text-[12px] leading-[15px] text-[#4C4C4C] font-[400] w-[80px] flex justify-center items-center flex-col text-center gap-y-[7px]"
                  >
                    <img
                      src={require("../assets/images/icon-add-circle.png")}
                      alt="Add icon"
                      className="w-[22px]"
                    />
                    Add Bundle Image
                  </label>
                </>
              )}
            </div>
            <ValidationError
              className={
                formik_subscriptionBundle.touched.image_url &&
                formik_subscriptionBundle.errors.image_url
                  ? "visible"
                  : "invisible"
              }
            >
              {formik_subscriptionBundle.errors.image_url}
            </ValidationError>
            <div
              className={`w-[128px] h-[128px] border border-solid rounded-[6px] bg-[#FFFFFF] m-auto flex justify-center items-center relative ${
                formik_subscriptionBundle.touched.detail_image_url &&
                formik_subscriptionBundle.errors.detail_image_url
                  ? "border-[#f44336]"
                  : "border-[#ACACAC]"
              }`}
            >
              {selectedDetailFile ||
              formik_subscriptionBundle.values.detail_image_url ? (
                <div className="w-full h-full relative">
                  <img
                    src={
                      selectedDetailFile ||
                      formik_subscriptionBundle.values.detail_image_url
                    }
                    onError={() =>
                      setSelectedDetailFile(
                        `${process.env.PUBLIC_URL}/no-image.png`,
                      )
                    }
                    alt="Preview"
                    className="m-[auto] w-full h-full object-cover rounded-[6px] p-[10px]"
                  />
                  <button
                    className="absolute top-[-10px] right-[-10px] rounded-[50%] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3]"
                    onClick={() => {
                      formik_subscriptionBundle.setFieldValue(
                        "detail_image_url",
                        "",
                      );
                      setSelectedDetailFile("");
                    }}
                  >
                    <img
                      src={require("../assets/images/icon-close.png")}
                      alt="Remove icon"
                      className="w-[25px]"
                    />
                  </button>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="detail_image_url"
                    name="detail_image_url"
                    hidden
                    onChange={(e: any) => {
                      onFileUpload(e, "detail");
                      formik_subscriptionBundle.setFieldValue(
                        "detail_image_url",
                        e?.target?.files[0],
                      );
                      setSelectedDetailFile(
                        URL.createObjectURL(e?.target?.files[0]),
                      );
                    }}
                  />
                  <label
                    htmlFor="detail_image_url"
                    className="text-[12px] leading-[15px] text-[#4C4C4C] font-[400] w-[80px] flex justify-center items-center flex-col text-center gap-y-[7px]"
                  >
                    <img
                      src={require("../assets/images/icon-add-circle.png")}
                      alt="Add icon"
                      className="w-[22px]"
                    />
                    Add Bundle Detail Image
                  </label>
                </>
              )}
            </div>
            <ValidationError
              className={
                formik_subscriptionBundle.touched.detail_image_url &&
                formik_subscriptionBundle.errors.detail_image_url
                  ? "visible"
                  : "invisible"
              }
            >
              {formik_subscriptionBundle.errors.detail_image_url}
            </ValidationError>
            <div className="order-9 md:order-9 xl:order-9">
              <label
                htmlFor="status"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block"
              >
                Status
              </label>
              <InputSwitch
                name="status"
                inputId="status"
                checked={formik_subscriptionBundle.values.status}
                onChange={formik_subscriptionBundle.handleChange}
                onBlur={formik_subscriptionBundle.handleBlur}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 w-full gap-4 bg-[#f7f3f3] p-4 rounded-[7px]">
            <div>
              <label
                htmlFor="plan name"
                className="text-[#292929] capitalize block mb-2"
              >
                Plan Name
              </label>
              <InputText
                className={`w-full ${
                  formik_subscriptionBundle.touched.plan_name &&
                  formik_subscriptionBundle.errors.plan_name
                    ? "p-invalid"
                    : ""
                }`}
                name="plan_name"
                id="plan_name"
                placeholder="Plan Name"
                value={formik_subscriptionBundle.values.plan_name}
                onChange={formik_subscriptionBundle.handleChange}
                onBlur={formik_subscriptionBundle.handleBlur}
              />
              <ValidationError
                className={
                  formik_subscriptionBundle.touched.plan_name &&
                  formik_subscriptionBundle.errors.plan_name
                    ? "block"
                    : "hidden"
                }
              >
                {formik_subscriptionBundle.errors.plan_name}
              </ValidationError>
            </div>
            {/* <div>
              <label
                htmlFor="api_key_id"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-2 block"
              >
                Sources
              </label>
              <Dropdown
                className={`w-full ${
                  formik_subscriptionBundle.touched.api_key_id &&
                  formik_subscriptionBundle.errors.api_key_id
                    ? "p-invalid"
                    : ""
                }`}
                options={sourcesList}
                name="api_key_id"
                inputId="api_key_id"
                placeholder="Sources"
                value={formik_subscriptionBundle.values.api_key_id}
                onChange={formik_subscriptionBundle.handleChange}
                onBlur={formik_subscriptionBundle.handleBlur}
              />
              <ValidationError
                className={
                  formik_subscriptionBundle.touched.api_key_id &&
                  formik_subscriptionBundle.errors.api_key_id
                    ? "block"
                    : "hidden"
                }
              >
                {formik_subscriptionBundle.errors.api_key_id}
              </ValidationError>
            </div> */}
            <div>
              <label
                htmlFor="sku"
                className="text-[#292929] capitalize block mb-2"
              >
                SKU
              </label>
              <InputText
                className={`w-full ${
                  formik_subscriptionBundle.touched.sku &&
                  formik_subscriptionBundle.errors.sku
                    ? "p-invalid"
                    : ""
                }`}
                name="sku"
                id="sku"
                placeholder="SKU"
                value={formik_subscriptionBundle.values.sku}
                onChange={formik_subscriptionBundle.handleChange}
                onBlur={formik_subscriptionBundle.handleBlur}
              />
              <ValidationError
                className={
                  formik_subscriptionBundle.touched.sku &&
                  formik_subscriptionBundle.errors.sku
                    ? "block"
                    : "hidden"
                }
              >
                {formik_subscriptionBundle.errors.sku}
              </ValidationError>
            </div>
            {formik_subscriptionBundle.values.id === 0 && (
              <div>
                <label
                  htmlFor="stock"
                  className="text-[#292929] capitalize block mb-2"
                >
                  Stock
                </label>
                <InputText
                  className={`w-full ${
                    formik_subscriptionBundle.touched.stock &&
                    formik_subscriptionBundle.errors.stock
                      ? "p-invalid"
                      : ""
                  }`}
                  name="stock"
                  id="stock"
                  placeholder="Stock"
                  value={formik_subscriptionBundle.values.stock}
                  onChange={formik_subscriptionBundle.handleChange}
                  onBlur={formik_subscriptionBundle.handleBlur}
                />
                <ValidationError
                  className={
                    formik_subscriptionBundle.touched.stock &&
                    formik_subscriptionBundle.errors.stock
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik_subscriptionBundle.errors.stock}
                </ValidationError>
              </div>
            )}
            <div className="md:col-span-2">
              <label
                htmlFor="subscription_option_id"
                className="text-[#292929] capitalize block mb-2"
              >
                Subscription Months
              </label>
              <Dropdown
                className={`w-full ${
                  formik_subscriptionBundle.touched.subscription_option_id &&
                  formik_subscriptionBundle.errors.subscription_option_id
                    ? "p-invalid"
                    : ""
                }`}
                options={subscriptionOptionList}
                name="subscription_option_id"
                inputId="subscription_option_id"
                placeholder="Subscription Months"
                value={formik_subscriptionBundle.values.subscription_option_id}
                onChange={formik_subscriptionBundle.handleChange}
                onBlur={formik_subscriptionBundle.handleBlur}
              />
              <ValidationError
                className={
                  formik_subscriptionBundle.touched.subscription_option_id &&
                  formik_subscriptionBundle.errors.subscription_option_id
                    ? "block"
                    : "hidden"
                }
              >
                {formik_subscriptionBundle.errors.subscription_option_id}
              </ValidationError>
            </div>
            {isRole(["Admin", "Manager"]) && (
              <>
                <div>
                  <label
                    htmlFor="base_plan.price"
                    className="text-[#292929] capitalize block mb-2"
                  >
                    Selling Price
                  </label>
                  <InputNumber
                    name="base_plan.price"
                    id="base_plan.price"
                    className={`w-full ${
                      formik_subscriptionBundle.touched?.base_plan?.price &&
                      formik_subscriptionBundle.errors?.base_plan?.price
                        ? "p-invalid"
                        : ""
                    }`}
                    placeholder="Selling Price"
                    value={formik_subscriptionBundle.values.base_plan.price}
                    onValueChange={(e) =>
                      formik_subscriptionBundle.setFieldValue(
                        "base_plan.price",
                        e.value,
                      )
                    }
                    onBlur={formik_subscriptionBundle.handleBlur}
                    prefix="$ "
                    minFractionDigits={2}
                  />
                  <ValidationError
                    className={
                      formik_subscriptionBundle.touched.base_plan?.price &&
                      formik_subscriptionBundle.errors.base_plan?.price
                        ? "block"
                        : "hidden"
                    }
                  >
                    {formik_subscriptionBundle.errors.base_plan?.price}
                  </ValidationError>
                </div>
                <div>
                  <label
                    htmlFor="base_plan.cost_price"
                    className="text-[#292929] capitalize block mb-2"
                  >
                    Cost Price
                  </label>
                  <InputNumber
                    name="base_plan.cost_price"
                    id="base_plan.cost_price"
                    className={`w-full ${
                      formik_subscriptionBundle.touched?.base_plan
                        ?.cost_price &&
                      formik_subscriptionBundle.errors?.base_plan?.cost_price
                        ? "p-invalid"
                        : ""
                    }`}
                    placeholder="Cost Price"
                    value={
                      formik_subscriptionBundle.values.base_plan?.cost_price
                    }
                    onValueChange={(e) =>
                      formik_subscriptionBundle.setFieldValue(
                        "base_plan.cost_price",
                        e.value,
                      )
                    }
                    onBlur={formik_subscriptionBundle.handleBlur}
                    prefix="$ "
                    minFractionDigits={2}
                  />
                  <ValidationError
                    className={
                      formik_subscriptionBundle.touched.base_plan?.cost_price &&
                      formik_subscriptionBundle.errors.base_plan?.cost_price
                        ? "block"
                        : "hidden"
                    }
                  >
                    {formik_subscriptionBundle.errors.base_plan?.cost_price}
                  </ValidationError>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex md:justify-start md:flex-col flex-col gap-4  mt-[20px]">
          <div className="grid md:grid-cols-2 xl:grid-cols-4 w-full gap-4 bg-[#f7f3f3] p-4 rounded-[7px]">
            {isRole(["Admin"]) && <></>}
            <div className="order-9 col-span-1 md:col-span-4">
              <Panel
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {`Total Beans Required - ${calculateTotalRequiredBeans(
                      formik_subscriptionBundle.values.base_plan.item_details,
                    )}`}
                    <ValidationError
                      className={`!p-0 ${
                        formik_subscriptionBundle.touched.base_plan
                          ?.total_beans &&
                        formik_subscriptionBundle.errors.base_plan?.total_beans
                          ? "block ml-2"
                          : "hidden"
                      }
              `}
                    >
                      {formik_subscriptionBundle.errors.base_plan?.total_beans}
                    </ValidationError>
                  </div>
                }
              >
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {formik_subscriptionBundle.values.base_plan.item_details.map(
                    (item: any, index: any) => (
                      <BeanInputOptional
                        key={index}
                        inputId={`bean-input-${index}`}
                        inputName={
                          subCategoryIdToLabel[
                            item.sub_category_id as SubCategoryID
                          ]?.toLowerCase() || item?.eg?.toLowerCase()
                        }
                        label={
                          subCategoryIdToLabel[
                            item.sub_category_id as SubCategoryID
                          ] || item.eg
                        }
                        checkBoxValue={`optional_${
                          subCategoryIdToLabel[
                            item.sub_category_id as SubCategoryID
                          ]?.toLowerCase() || item?.eg?.toLowerCase()
                        }`}
                        inputValue={item.quantity}
                        beansOptional={Boolean(item.optional)}
                        onBeansOptionalChange={(value) =>
                          onBeansOptionalChange(index, Boolean(value))
                        }
                        handleInputOnChange={(value) => {
                          formik_subscriptionBundle.setFieldValue(
                            `base_plan.item_details[${index}].quantity`,
                            value,
                          );
                        }}
                      />
                    ),
                  )}
                </div>
              </Panel>
            </div>

            {/* <div className="order-10 col-span-1 md:col-span-4">
              <Fieldset legend="Free Gift" toggleable>
                {focItemDetails.length > 0 &&
                  focItemDetails.map((group: any, index: any) => (
                    <div
                      key={index}
                      className={`grid grid-cols-1 md:grid-cols-2 gap-4 items-start ${
                        index !== focItemDetails.length - 1 ? "border-b-2" : ""
                      } ${index === 0 ? "pb-[30px]" : "py-[30px]"}
                  `}
                    >
                      <div className="col-span-2">
                        <label
                          htmlFor={`api_key_id_${index}`}
                          className="text-[16px] text-[#292929] font-[500] capitalize mb-2 block"
                        >
                          {index + 1}. Sources
                        </label>
                        <Dropdown
                          name={`base_plan.foc.item_details[${index}].api_key_id`}
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan?.foc
                              ?.item_details?.[index]?.api_key_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.api_key_id
                              ? "p-invalid"
                              : ""
                          }`}
                          options={sourcesList}
                          inputId={`api_key_id_${index}`}
                          placeholder="Select Source"
                          value={
                            formik_subscriptionBundle.values.base_plan.foc
                              .item_details[index].api_key_id
                          }
                          onChange={(e) =>
                            handleInputChangeFocItem(
                              index,
                              "api_key_id",
                              e.value || 0,
                            )
                          }
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundle.touched.base_plan?.foc
                              ?.item_details?.[index]?.api_key_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.api_key_id &&
                            selectedCategories[index] !== 0
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.api_key_id
                          }
                        </ValidationError>
                      </div>
                      <div>
                        <label
                          htmlFor={`FocItemCategory_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {index + 1}. Gift Type
                        </label>
                        <Dropdown
                          name={`base_plan.foc.item_details[${index}].sub_category_id`}
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan?.foc
                              ?.item_details?.[index]?.sub_category_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.sub_category_id
                              ? "p-invalid"
                              : ""
                          }`}
                          options={freeGiftCategoryList}
                          value={selectedCategories[index]} // Use selectedCategories[index]
                          placeholder="Select Gift Type"
                          onChange={(e) => {
                            const updatedCategories = [...selectedCategories];
                            updatedCategories[index] = e.value;
                            setSelectedCategories(updatedCategories);

                            // Clear the product_id for this index
                            const updatedFocItemDetails = focItemDetails.map(
                              (item: any, i: any) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    product_id: null, // Clear the product_id
                                    sub_category_id: 0,
                                  };
                                }
                                return item;
                              },
                            );
                            setFocItemDetails(updatedFocItemDetails);

                            formik_subscriptionBundle.setFieldValue(
                              `base_plan.foc.item_details[${index}].sub_category_id`,
                              e.value,
                            );

                            if (e.value === 1) {
                              getProductList(index); // Pass the current dropdown index
                            } else if (e.value === 2) {
                              getProductCategory(index); // Pass the current dropdown index
                            } else {
                              const updatedFreeGiftProductsLists = [
                                ...freeGiftProductsLists,
                              ];
                              updatedFreeGiftProductsLists[index] = [];
                              setFreeGiftProductsLists(
                                updatedFreeGiftProductsLists,
                              );
                            }
                          }}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundle.touched.base_plan?.foc
                              ?.item_details?.[index]?.sub_category_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.sub_category_id &&
                            selectedCategories[index] !== 0
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.sub_category_id
                          }
                        </ValidationError>
                      </div>

                      <div>
                        <label
                          htmlFor={`FocItem_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {index + 1}. Free Gift
                        </label>
                        <Dropdown
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan?.foc
                              ?.item_details?.[index]?.product_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.product_id
                              ? "p-invalid"
                              : ""
                          }`}
                          filter
                          options={freeGiftProductsLists[index] || []} // Access the correct list based on index
                          name={`focItem_${index}`}
                          inputId={`focItem_${index}`}
                          placeholder="Select Free Gift"
                          value={group.product_id || group.sub_category_id}
                          onChange={(e) => {
                            const selectedValue = e.value;
                            const selectedItem = (
                              freeGiftProductsLists[index] || []
                            ).find((item: any) => item.value === selectedValue);

                            if (selectedItem) {
                              const updatedFocItemDetails = focItemDetails.map(
                                (item: any, i: any) => {
                                  if (i === index) {
                                    if (selectedCategories[index] === 1) {
                                      // If category is 1, set both sub_category_id and product_id
                                      return {
                                        ...item,
                                        sub_category_id:
                                          selectedItem.sub_category_id,
                                        label: selectedItem.label,
                                        product_id: selectedValue,
                                      };
                                    } else if (
                                      selectedCategories[index] === 2
                                    ) {
                                      // If category is 2, only set sub_category_id and clear product_id
                                      return {
                                        ...item,
                                        sub_category_id:
                                          selectedItem.sub_category_id,
                                        label: selectedItem.label,
                                        product_id: 0,
                                      };
                                    }
                                  }
                                  return item;
                                },
                              );
                              setFocItemDetails(updatedFocItemDetails);
                            }
                            if (selectedCategories[index] === 1) {
                              formik_subscriptionBundle.setFieldValue(
                                `base_plan.foc.item_details[${index}].product_id`,
                                e.value,
                              );
                              formik_subscriptionBundle.setFieldValue(
                                `base_plan.foc.item_details[${index}].sub_category_id`,
                                selectedItem?.sub_category_id,
                              );
                            }
                            if (selectedCategories[index] === 2) {
                              formik_subscriptionBundle.setFieldValue(
                                `base_plan.foc.item_details[${index}].product_id`,
                                0,
                              );
                              formik_subscriptionBundle.setFieldValue(
                                `base_plan.foc.item_details[${index}].sub_category_id`,
                                selectedItem?.sub_category_id,
                              );
                            }
                          }}
                          optionLabel="label"
                          optionValue="value"
                          itemTemplate={(option) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {option.image_url && (
                                <img
                                  src={option.image_url}
                                  alt={option.label}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    marginRight: "10px",
                                  }}
                                />
                              )}
                              <span>{option.label}</span>
                            </div>
                          )}
                        />
                        <ValidationError
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan?.foc
                              ?.item_details?.[index]?.product_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.product_id
                              ? "block"
                              : "hidden"
                          }`}
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.product_id
                          }
                        </ValidationError>
                      </div>
                      <div>
                        <label
                          htmlFor={`FocItemQty_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {index + 1}. Quantity
                        </label>
                        <InputNumber
                          value={group.quantity}
                          onChange={(e) =>
                            handleInputChangeFocItem(
                              index,
                              "quantity",
                              e.value || 0,
                            )
                          }
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan?.foc
                              ?.item_details?.[index]?.quantity &&
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.quantity
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundle.touched.base_plan?.foc
                              ?.item_details?.[index]?.quantity &&
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.quantity
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.quantity
                          }
                        </ValidationError>
                      </div>
                      <div>
                        <label
                          htmlFor={`FocItemFreeGiftNo_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          Free Gift Group Number
                        </label>
                        <InputNumber
                          id={`FocItemFreeGiftNo_${index}`}
                          value={group.freegift_no ?? 0}
                          onValueChange={(e) => {
                            const updatedFocItemDetails = [...focItemDetails];
                            updatedFocItemDetails[index] = {
                              ...group,
                              freegift_no: e.value ?? 0,
                            };
                            setFocItemDetails(updatedFocItemDetails);

                            formik_subscriptionBundle.setFieldValue(
                              `base_plan.foc.item_details[${index}].freegift_no`,
                              e.value ?? 0,
                            );
                          }}
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan?.foc
                              ?.item_details?.[index]?.freegift_no &&
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.freegift_no
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundle.touched.base_plan?.foc
                              ?.item_details?.[index]?.freegift_no &&
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.freegift_no
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.base_plan?.foc
                                ?.item_details?.[index] as any
                            )?.freegift_no
                          }
                        </ValidationError>
                      </div>
                      <div></div>
                      <div>
                        <Checkbox
                          inputId={`optionalFocItem_${index}`}
                          checked={group.optional === 1}
                          onChange={(e) => {
                            const updatedFocItemDetails = [...focItemDetails];
                            updatedFocItemDetails[index] = {
                              ...group,
                              optional: e.checked ? 1 : 0,
                            };
                            setFocItemDetails(updatedFocItemDetails);

                            formik_subscriptionBundle.setFieldValue(
                              `base_plan.foc.item_details[${index}].optional`,
                              e.checked ? 1 : 0,
                            );
                          }}
                        />
                        <label
                          htmlFor={`optionalFocItem_${index}`}
                          className="ml-2"
                        >
                          Optional
                        </label>
                      </div>

                      <div className={`mt-auto`}>
                        <button
                          onClick={() => removeFocItemGroup(index)}
                          type="button"
                          className="rounded-[10px] bg-red-500 text-white py-[9px] px-[24px] w-fit"
                        >
                          {focItemDetails.length === 1
                            ? "Disable Free Gift"
                            : `Remove Free Gift`}
                        </button>
                      </div>
                    </div>
                  ))}
                <button
                  type="button"
                  onClick={addFocItemGroup}
                  className="rounded-[10px] bg-[#82d7d2] py-[9px] px-[24px]"
                >
                  {focItemDetails.length > 0
                    ? "Add Another Free Gift"
                    : "Enable Free Gift"}
                </button>
              </Fieldset>
            </div>
            <div className="order-11 col-span-1 md:col-span-4">
              <Fieldset legend="Advance Free Gift" toggleable>
                {focAdvanceItemDetails.length > 0 && (
                  <div className="pb-[16px]">
                    <label
                      htmlFor="Advance Free Gift"
                      className="text-[#292929] capitalize block mb-2"
                    >
                      Minimumn Bundle Purchase Quantity
                    </label>
                    <InputNumber
                      className={`${
                        formik_subscriptionBundle.touched.base_plan?.advance_foc
                          ?.minimum_bundle_purchase_qty &&
                        formik_subscriptionBundle.errors.base_plan?.advance_foc
                          ?.minimum_bundle_purchase_qty
                          ? "p-invalid"
                          : ""
                      }`}
                      placeholder="Purchase Quantity"
                      value={
                        formik_subscriptionBundle.values.base_plan?.advance_foc
                          .minimum_bundle_purchase_qty
                      }
                      onValueChange={(e) =>
                        formik_subscriptionBundle.setFieldValue(
                          "base_plan.advance_foc.minimum_bundle_purchase_qty",
                          e.value,
                        )
                      }
                      onBlur={formik_subscriptionBundle.handleBlur}
                    />
                    <ValidationError
                      className={
                        formik_subscriptionBundle.touched.base_plan?.advance_foc
                          ?.minimum_bundle_purchase_qty &&
                        formik_subscriptionBundle.errors.base_plan?.advance_foc
                          ?.minimum_bundle_purchase_qty
                          ? "block"
                          : "hidden"
                      }
                    >
                      {
                        formik_subscriptionBundle.errors.base_plan?.advance_foc
                          ?.minimum_bundle_purchase_qty
                      }
                    </ValidationError>
                  </div>
                )}
                {focAdvanceItemDetails.length > 0 &&
                  focAdvanceItemDetails.map((group: any, index: any) => (
                    <div
                      key={index}
                      className={`grid grid-cols-1 md:grid-cols-2 gap-4 items-start ${
                        index !== focAdvanceItemDetails.length - 1
                          ? "border-b-2"
                          : ""
                      } ${index === 0 ? "pb-[30px]" : "py-[30px]"}`}
                    >
                      <div className="col-span-2">
                        <label
                          htmlFor={`advance_api_key_id_${index}`}
                          className="text-[16px] text-[#292929] font-[500] capitalize mb-2 block"
                        >
                          {index + 1}. Sources
                        </label>
                        <Dropdown
                          name={`base_plan.advance_foc.item_details[${index}].api_key_id`}
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan
                              ?.advance_foc?.item_details?.[index]
                              ?.api_key_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.api_key_id
                              ? "p-invalid"
                              : ""
                          }`}
                          options={sourcesList}
                          inputId={`api_key_id_${index}`}
                          placeholder="Select Source"
                          value={
                            formik_subscriptionBundle.values.base_plan
                              .advance_foc.item_details[index].api_key_id
                          }
                          onChange={(e) =>
                            handleInputChangeFocAdvanceItem(
                              index,
                              "api_key_id",
                              e.value || 0,
                            )
                          }
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundle.touched.base_plan
                              ?.advance_foc?.item_details?.[index]
                              ?.api_key_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.api_key_id &&
                            selectedAdvanceCategories[index] !== 0
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.api_key_id
                          }
                        </ValidationError>
                      </div>
                      <div>
                        <label
                          htmlFor={`AdvanceFocItemCategory_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {index + 1}. Gift Type
                        </label>
                        <Dropdown
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan
                              ?.advance_foc?.item_details?.[index]
                              ?.sub_category_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.sub_category_id
                              ? "p-invalid"
                              : ""
                          }`}
                          options={freeGiftCategoryList}
                          value={selectedAdvanceCategories[index]}
                          placeholder="Select Gift Type"
                          onChange={(e) => {
                            const updatedCategories = [
                              ...selectedAdvanceCategories,
                            ];
                            updatedCategories[index] = e.value;
                            setSelectedAdvanceCategories(updatedCategories);

                            // Clear the existing product_id and sub_category_id values when category changes
                            const clearedFocAdvanceItemDetails =
                              focAdvanceItemDetails.map((item: any, i: any) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    product_id: null,
                                    sub_category_id: 0,
                                  };
                                }
                                return item;
                              });
                            setFocAdvanceItemDetails(
                              clearedFocAdvanceItemDetails,
                            );

                            formik_subscriptionBundle.setFieldValue(
                              `base_plan.advance_foc.item_details[${index}].sub_category_id`,
                              e.value,
                            );

                            // Fetch data based on the new category selection
                            if (e.value === 1) {
                              getAdvanceProductList(index);
                            } else if (e.value === 2) {
                              getAdvanceProductCategory(index);
                            } else {
                              const updatedProductLists = [
                                ...freeGiftProductsListAdvance,
                              ];
                              updatedProductLists[index] = [];
                              setFreeGiftProductsListAdvance(
                                updatedProductLists,
                              );
                            }
                          }}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundle.touched.base_plan
                              ?.advance_foc?.item_details?.[index]
                              ?.sub_category_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.sub_category_id &&
                            selectedAdvanceCategories[index] !== 0
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.sub_category_id
                          }
                        </ValidationError>
                      </div>
                      <div>
                        <label
                          htmlFor={`FocItem_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {index + 1}. Free Gift
                        </label>
                        <Dropdown
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan
                              ?.advance_foc?.item_details?.[index]
                              ?.product_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.product_id
                              ? "p-invalid"
                              : ""
                          }`}
                          filter
                          options={freeGiftProductsListAdvance[index] || []}
                          name={`advanceFocItem_${index}`}
                          inputId={`advanceFocItem_${index}`}
                          placeholder="Select Free Gift"
                          value={group.product_id || group.sub_category_id}
                          onChange={(e) => {
                            const selectedValue = e.value;
                            const selectedItem = (
                              freeGiftProductsListAdvance[index] || []
                            ).find((item: any) => item.value === selectedValue);

                            if (selectedItem) {
                              const updatedFocAdvanceItemDetails =
                                focAdvanceItemDetails.map(
                                  (item: any, i: any) => {
                                    if (i === index) {
                                      if (
                                        selectedAdvanceCategories[index] === 1
                                      ) {
                                        // If category is 1, set both sub_category_id and product_id
                                        return {
                                          ...item,
                                          sub_category_id:
                                            selectedItem.sub_category_id,
                                          product_id: selectedValue,
                                        };
                                      } else if (
                                        selectedAdvanceCategories[index] === 2
                                      ) {
                                        // If category is 2, only set sub_category_id and clear product_id
                                        return {
                                          ...item,
                                          sub_category_id:
                                            selectedItem.sub_category_id,
                                          product_id: 0,
                                        };
                                      }
                                    }
                                    return item;
                                  },
                                );
                              setFocAdvanceItemDetails(
                                updatedFocAdvanceItemDetails,
                              );
                              if (selectedAdvanceCategories[index] === 1) {
                                formik_subscriptionBundle.setFieldValue(
                                  `base_plan.advance_foc.item_details[${index}].product_id`,
                                  e.value,
                                );
                                formik_subscriptionBundle.setFieldValue(
                                  `base_plan.advance_foc.item_details[${index}].sub_category_id`,
                                  selectedItem?.sub_category_id,
                                );
                              }
                              if (selectedAdvanceCategories[index] === 2) {
                                formik_subscriptionBundle.setFieldValue(
                                  `base_plan.advance_foc.item_details[${index}].product_id`,
                                  0,
                                );
                                formik_subscriptionBundle.setFieldValue(
                                  `base_plan.advance_foc.item_details[${index}].sub_category_id`,
                                  selectedItem?.sub_category_id,
                                );
                              }
                            }
                          }}
                          optionLabel="label"
                          optionValue="value"
                          itemTemplate={(option) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {option.image_url && (
                                <img
                                  src={option.image_url}
                                  alt={option.label}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    marginRight: "10px",
                                  }}
                                />
                              )}
                              <span>{option.label}</span>
                            </div>
                          )}
                        />
                        <ValidationError
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan
                              ?.advance_foc?.item_details?.[index]
                              ?.product_id &&
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.product_id
                              ? "block"
                              : "hidden"
                          }`}
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.product_id
                          }
                        </ValidationError>
                      </div>
                      <div>
                        <label
                          htmlFor={`AdvanceFocItemQty_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {index + 1}. Quantity
                        </label>
                        <InputNumber
                          value={group.quantity}
                          onChange={(e) =>
                            handleInputChangeFocAdvanceItem(
                              index,
                              "quantity",
                              e.value || 0,
                            )
                          }
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan
                              ?.advance_foc?.item_details?.[index]?.quantity &&
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.quantity
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundle.touched.base_plan
                              ?.advance_foc?.item_details?.[index]?.quantity &&
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.quantity
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.quantity
                          }
                        </ValidationError>
                      </div>
                      <div>
                        <label
                          htmlFor={`AdvanceFocItemFreeGiftNo_${index}`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          Free Gift Group Number
                        </label>
                        <InputNumber
                          id={`AdvanceFocItemFreeGiftNo_${index}`}
                          value={group.freegift_no ?? 0}
                          onValueChange={(e) => {
                            const updatedFocAdvanceItemDetails =
                              focAdvanceItemDetails.map((item: any, i: any) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    freegift_no: e.value ?? 0,
                                  };
                                }
                                return item;
                              });

                            setFocAdvanceItemDetails(
                              updatedFocAdvanceItemDetails,
                            );

                            formik_subscriptionBundle.setFieldValue(
                              `base_plan.advance_foc.item_details[${index}].freegift_no`,
                              e.value ?? 0,
                            );
                          }}
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan
                              ?.advance_foc?.item_details?.[index]
                              ?.freegift_no &&
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.freegift_no
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <ValidationError
                          className={`w-full ${
                            formik_subscriptionBundle.touched.base_plan
                              ?.advance_foc?.item_details?.[index]
                              ?.freegift_no &&
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.freegift_no
                              ? "block"
                              : "hidden"
                          }`}
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.base_plan
                                ?.advance_foc?.item_details?.[index] as any
                            )?.freegift_no
                          }
                        </ValidationError>
                      </div>
                      <div></div>
                      <div>
                        <Checkbox
                          inputId={`optionalAdvanceCheckbox_${index}`}
                          checked={group.optional === 1}
                          onChange={(e) => {
                            const updatedFocAdvanceItemDetails =
                              focAdvanceItemDetails.map((item: any, i: any) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    optional: e.checked ? 1 : 0,
                                  };
                                }
                                return item;
                              });

                            setFocAdvanceItemDetails(
                              updatedFocAdvanceItemDetails,
                            );

                            formik_subscriptionBundle.setFieldValue(
                              `base_plan.advance_foc.item_details[${index}].optional`,
                              e.checked ? 1 : 0,
                            );
                          }}
                        />
                        <label
                          htmlFor={`optionalAdvanceCheckbox_${index}`}
                          className="ml-2"
                        >
                          Optional
                        </label>
                      </div>
                      <div className={`mt-auto}`}>
                        <button
                          onClick={() => removeFocAdvanceItemGroup(index)}
                          type="button"
                          className="rounded-[10px] bg-red-500 text-white py-[9px] px-[24px] w-fit"
                        >
                          {focAdvanceItemDetails.length === 1
                            ? "Disable Advance Gift"
                            : `Remove Advance Gift`}
                        </button>
                      </div>
                    </div>
                  ))}
                <button
                  type="button"
                  onClick={addFocAdvanceItemGroup}
                  className="rounded-[10px] bg-[#82d7d2] py-[9px] px-[24px]"
                >
                  {focAdvanceItemDetails.length > 0
                    ? "Add Another Advance Gift"
                    : "Enable Advance Gift"}
                </button>
              </Fieldset>
            </div> */}
          </div>
        </div>
        <div className="bg-[#f7f3f3] p-4 rounded-[7px] mt-[20px]">
          <Fieldset legend="Add On Option" toggleable>
            {formik_subscriptionBundle.values.add_on_plans?.map(
              (plan: any, planIndex: any) => (
                <div
                  key={planIndex}
                  className={` ${
                    planIndex !==
                    formik_subscriptionBundle.values.add_on_plans.length - 1
                      ? "border-b-2"
                      : ""
                  }
            ${planIndex === 0 ? "pb-[30px]" : "py-[30px]"}
`}
                >
                  {isRole(["Admin"]) && (
                    <div className="grid md:grid-cols-3 xl:grid-cols-4 w-full gap-4">
                      <div className="order-5 md:order-6 xl:order-5">
                        <label
                          htmlFor={`add_on_plans[${planIndex}].add_on_name`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {planIndex + 1}. Add On Name
                        </label>
                        <InputText
                          id={`add_on_plans[${planIndex}].add_on_name`}
                          className={`w-full ${
                            formik_subscriptionBundle.touched?.add_on_plans?.[
                              planIndex
                            ]?.add_on_name &&
                            (
                              formik_subscriptionBundle.errors.add_on_plans?.[
                                planIndex
                              ] as any
                            )?.add_on_name
                              ? "p-invalid"
                              : ""
                          }`}
                          placeholder="Add On Name"
                          value={plan.add_on_name || ""}
                          onChange={formik_subscriptionBundle.handleChange}
                          onBlur={formik_subscriptionBundle.handleBlur}
                        />

                        <ValidationError
                          className={
                            formik_subscriptionBundle.touched.add_on_plans?.[
                              planIndex
                            ]?.add_on_name &&
                            (
                              formik_subscriptionBundle.errors.add_on_plans?.[
                                planIndex
                              ] as any
                            )?.add_on_name
                              ? "block"
                              : "hidden"
                          }
                        >
                          {(
                            formik_subscriptionBundle.errors.add_on_plans?.[
                              planIndex
                            ] as any
                          )?.add_on_name || ""}
                        </ValidationError>
                      </div>
                      <div className="order-6 md:order-7 xl:order-6">
                        <label
                          htmlFor={`add_on_plans[${planIndex}].cost_price`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {planIndex + 1}. Cost Price
                        </label>
                        <InputNumber
                          name={`add_on_plans[${planIndex}].cost_price`}
                          id={`add_on_plans[${planIndex}].cost_price`}
                          className={`w-full ${
                            formik_subscriptionBundle.touched?.add_on_plans?.[
                              planIndex
                            ]?.cost_price &&
                            (
                              formik_subscriptionBundle.errors.add_on_plans?.[
                                planIndex
                              ] as any
                            )?.cost_price
                              ? "p-invalid"
                              : ""
                          }`}
                          placeholder="Cost Price"
                          value={plan.cost_price || ""}
                          onValueChange={(e) =>
                            formik_subscriptionBundle.setFieldValue(
                              `add_on_plans[${planIndex}].cost_price`,
                              e.value,
                            )
                          }
                          onBlur={formik_subscriptionBundle.handleBlur}
                          prefix="$ "
                          minFractionDigits={2}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundle.touched.add_on_plans?.[
                              planIndex
                            ]?.cost_price &&
                            (
                              formik_subscriptionBundle.errors.add_on_plans?.[
                                planIndex
                              ] as any
                            )?.cost_price
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.add_on_plans?.[
                                planIndex
                              ] as any
                            )?.cost_price
                          }
                        </ValidationError>
                      </div>

                      <div className="flex-1 order-7 md:order-6 xl:order-5">
                        <label
                          htmlFor={`add_on_plans[${planIndex}].price`}
                          className="text-[#292929] capitalize block mb-2"
                        >
                          {planIndex + 1}. Selling Price
                        </label>
                        <InputNumber
                          name={`add_on_plans[${planIndex}].price`}
                          id={`add_on_plans[${planIndex}].price`}
                          className={`w-full ${
                            formik_subscriptionBundle.touched?.add_on_plans?.[
                              planIndex
                            ]?.price &&
                            (
                              formik_subscriptionBundle.errors.add_on_plans?.[
                                planIndex
                              ] as any
                            )?.price
                              ? "p-invalid"
                              : ""
                          }`}
                          placeholder="Selling Price"
                          value={plan.price || ""}
                          onValueChange={(e) =>
                            formik_subscriptionBundle.setFieldValue(
                              `add_on_plans[${planIndex}].price`,
                              e.value,
                            )
                          }
                          onBlur={formik_subscriptionBundle.handleBlur}
                          prefix="$ "
                          minFractionDigits={2}
                        />
                        <ValidationError
                          className={
                            formik_subscriptionBundle.touched.add_on_plans?.[
                              planIndex
                            ]?.price &&
                            (
                              formik_subscriptionBundle.errors.add_on_plans?.[
                                planIndex
                              ] as any
                            )?.price
                              ? "block"
                              : "hidden"
                          }
                        >
                          {
                            (
                              formik_subscriptionBundle.errors.add_on_plans?.[
                                planIndex
                              ] as any
                            )?.price
                          }
                        </ValidationError>
                      </div>
                      <div className="order-9 col-span-1 md:col-span-4">
                        <Panel
                          header={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {`${
                                planIndex + 1
                              }. Total Beans Required - ${calculateTotalAddOnRequiredBeans(
                                plan.item_details,
                              )}`}
                              <ValidationError
                                className={`!p-0 ${
                                  formik_subscriptionBundle.touched
                                    .add_on_plans?.[planIndex]?.total_beans &&
                                  (
                                    formik_subscriptionBundle.errors
                                      .add_on_plans?.[planIndex] as any
                                  )?.total_beans
                                    ? "block ml-2"
                                    : "hidden"
                                }`}
                              >
                                {
                                  (
                                    formik_subscriptionBundle.errors
                                      .add_on_plans?.[planIndex] as any
                                  )?.total_beans
                                }
                              </ValidationError>
                            </div>
                          }
                        >
                          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                            {plan.item_details.map(
                              (item: any, itemIndex: any) => {
                                // Use sub_category_id to determine the label
                                const label =
                                  subCategoryIdToLabel[
                                    item.sub_category_id as SubCategoryID
                                  ] || item.eg;

                                return (
                                  <BeanInputOptional
                                    key={`item-${planIndex}-${itemIndex}`}
                                    inputId={`bean-input-${planIndex}-${itemIndex}`}
                                    inputName={label.toLowerCase()}
                                    label={label}
                                    checkBoxValue={`optional_${label.toLowerCase()}`}
                                    inputValue={item.quantity}
                                    beansOptional={Boolean(item.optional)}
                                    onBeansOptionalChange={(value) =>
                                      onBeansAddOnOptionalChange(
                                        planIndex,
                                        itemIndex,
                                        Boolean(value),
                                      )
                                    }
                                    handleInputOnChange={(value) => {
                                      formik_subscriptionBundle.setFieldValue(
                                        `add_on_plans[${planIndex}].item_details[${itemIndex}].quantity`,
                                        value,
                                      );
                                    }}
                                  />
                                );
                              },
                            )}
                          </div>
                        </Panel>
                      </div>
                      <div className="order-10 col-span-1 md:col-span-4">
                        <Fieldset legend="Free Gift" toggleable>
                          {focAddOnItemDetails[planIndex]?.map(
                            (group: any, index: any) => (
                              <div
                                key={index}
                                className={`grid grid-cols-1 md:grid-cols-2 gap-4 items-start ${
                                  index !==
                                  focAddOnItemDetails[planIndex]?.length - 1
                                    ? "border-b-2"
                                    : ""
                                } ${index === 0 ? "pb-[30px]" : "py-[30px]"}
`}
                              >
                                <div className="col-span-2">
                                  <label
                                    htmlFor={`add_on_api_key_id_${index}`}
                                    className="text-[16px] text-[#292929] font-[500] capitalize mb-2 block"
                                  >
                                    {index + 1}. Sources
                                  </label>
                                  <Dropdown
                                    name={`add_on_plans.foc.item_details[${index}].api_key_id`}
                                    className={`w-full ${
                                      formik_subscriptionBundle.touched
                                        .add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.api_key_id &&
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.api_key_id
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                    options={sourcesList}
                                    inputId={`api_key_id_${index}`}
                                    placeholder="Select Source"
                                    value={
                                      formik_subscriptionBundle.values
                                        .add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.api_key_id
                                    }
                                    onChange={(e) =>
                                      handleInputChangeFocAddOnItem(
                                        planIndex,
                                        index,
                                        "api_key_id",
                                        e.value || 0,
                                      )
                                    }
                                  />
                                  <ValidationError
                                    className={`${
                                      formik_subscriptionBundle.touched
                                        .add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.api_key_id &&
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.api_key_id
                                        ? "block"
                                        : "hidden"
                                    }`}
                                  >
                                    {
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.api_key_id
                                    }
                                  </ValidationError>
                                </div>
                                <div>
                                  <label
                                    htmlFor={`FocItemCategory_${index}`}
                                    className="text-[#292929] capitalize block mb-2"
                                  >
                                    {index + 1}. Gift Type
                                  </label>
                                  <Dropdown
                                    className={`w-full ${
                                      formik_subscriptionBundle.touched
                                        .add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]
                                        ?.sub_category_id &&
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.sub_category_id
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                    options={freeGiftCategoryList}
                                    value={
                                      (selectedAddOnCategories as any)[
                                        planIndex
                                      ]?.[index] ?? ""
                                    }
                                    placeholder="Select Gift Type"
                                    onChange={(e) => {
                                      const updatedAddOnCategories: any = {
                                        ...selectedAddOnCategories,
                                      };

                                      if (!updatedAddOnCategories[planIndex]) {
                                        updatedAddOnCategories[planIndex] = [];
                                      }
                                      updatedAddOnCategories[planIndex][index] =
                                        e.value;
                                      setSelectedAddOnCategories(
                                        updatedAddOnCategories,
                                      );

                                      const updatedFocAddOnItemDetails: any = {
                                        ...focAddOnItemDetails,
                                      };
                                      if (
                                        !updatedFocAddOnItemDetails[planIndex]
                                      ) {
                                        updatedFocAddOnItemDetails[planIndex] =
                                          [];
                                      }
                                      if (
                                        !updatedFocAddOnItemDetails[planIndex][
                                          index
                                        ]
                                      ) {
                                        updatedFocAddOnItemDetails[planIndex][
                                          index
                                        ] = {
                                          sub_category_id: 0,
                                          product_id: null,
                                          quantity: 0,
                                        };
                                      }
                                      updatedFocAddOnItemDetails[planIndex][
                                        index
                                      ].sub_category_id = e.value;

                                      // Reset product_id when category is changed
                                      updatedFocAddOnItemDetails[planIndex][
                                        index
                                      ].product_id = null;
                                      updatedFocAddOnItemDetails[planIndex][
                                        index
                                      ].sub_category_id = 0;
                                      setFocAddOnItemDetails(
                                        updatedFocAddOnItemDetails,
                                      );

                                      formik_subscriptionBundle.setFieldValue(
                                        `add_on_plans[${planIndex}].foc.item_details[${index}].sub_category_id`,
                                        e.value,
                                      );

                                      if (e.value === 1) {
                                        getAddOnProductList(planIndex, index);
                                      } else if (e.value === 2) {
                                        getAddOnProductCategory(
                                          planIndex,
                                          index,
                                        );
                                      } else {
                                        const updatedProductLists: any = {
                                          ...freeGiftAddOnProductsList,
                                        };
                                        if (!updatedProductLists[planIndex]) {
                                          updatedProductLists[planIndex] = [];
                                        }
                                        updatedProductLists[planIndex][index] =
                                          [];
                                        setFreeGiftAddOnProductsList(
                                          updatedProductLists,
                                        );
                                      }
                                    }}
                                  />
                                  <ValidationError
                                    className={`${
                                      formik_subscriptionBundle.touched
                                        .add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]
                                        ?.sub_category_id &&
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.sub_category_id
                                        ? "block"
                                        : "hidden"
                                    }`}
                                  >
                                    {
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.sub_category_id
                                    }
                                  </ValidationError>
                                </div>
                                <div>
                                  <label
                                    htmlFor={`FocItem_${index}`}
                                    className="text-[#292929] capitalize block mb-2"
                                  >
                                    {index + 1}. Free Gift
                                  </label>
                                  <Dropdown
                                    className={`w-full ${
                                      formik_subscriptionBundle.touched
                                        .add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.product_id &&
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.product_id
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                    filter
                                    options={
                                      freeGiftAddOnProductsList[planIndex]?.[
                                        index
                                      ] ?? []
                                    }
                                    name={`focItem_${index}`}
                                    inputId={`focItem_${index}`}
                                    placeholder="Select Free Gift"
                                    value={
                                      group.product_id || group.sub_category_id
                                    }
                                    onChange={(e) => {
                                      const updatedFocAddOnItemDetails = {
                                        ...focAddOnItemDetails,
                                      };
                                      const selectedAddOnProductsId = e.value;

                                      const selectedAddOnProducts =
                                        freeGiftAddOnProductsList[planIndex]?.[
                                          index
                                        ]?.find(
                                          (product: any) =>
                                            product.value ===
                                            selectedAddOnProductsId,
                                        );

                                      if (
                                        !updatedFocAddOnItemDetails[planIndex]
                                      ) {
                                        updatedFocAddOnItemDetails[planIndex] =
                                          [];
                                      }

                                      if (
                                        !updatedFocAddOnItemDetails[planIndex][
                                          index
                                        ]
                                      ) {
                                        updatedFocAddOnItemDetails[planIndex][
                                          index
                                        ] = {};
                                      }

                                      const targetGroup =
                                        updatedFocAddOnItemDetails[planIndex][
                                          index
                                        ];
                                      targetGroup.sub_category_id =
                                        selectedAddOnProducts?.sub_category_id ||
                                        0;
                                      targetGroup.eg =
                                        selectedAddOnProducts?.label || "";
                                      // Check if selectedAddOnCategories[planIndex][index] is 2 and set product_id to 0
                                      if (
                                        (
                                          selectedAddOnCategories[
                                            planIndex
                                          ] as any
                                        )[index] === 2
                                      ) {
                                        targetGroup.product_id = 0;
                                        formik_subscriptionBundle.setFieldValue(
                                          `add_on_plans[${planIndex}].foc.item_details[${index}].product_id`,
                                          targetGroup.product_id,
                                        );
                                        formik_subscriptionBundle.setFieldValue(
                                          `add_on_plans[${planIndex}].foc.item_details[${index}].sub_category_id`,
                                          targetGroup.sub_category_id,
                                        );
                                      } else {
                                        targetGroup.product_id =
                                          selectedAddOnProductsId;
                                        formik_subscriptionBundle.setFieldValue(
                                          `add_on_plans[${planIndex}].foc.item_details[${index}].product_id`,
                                          e.value,
                                        );
                                        formik_subscriptionBundle.setFieldValue(
                                          `add_on_plans[${planIndex}].foc.item_details[${index}].sub_category_id`,
                                          targetGroup.sub_category_id,
                                        );
                                      }

                                      setFocAddOnItemDetails(
                                        updatedFocAddOnItemDetails,
                                      );
                                    }}
                                    optionLabel="label"
                                    optionValue="value"
                                    itemTemplate={(option) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {option.image_url && (
                                          <img
                                            src={option.image_url}
                                            alt={option.label}
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              marginRight: "10px",
                                            }}
                                          />
                                        )}
                                        <span>{option.label}</span>
                                      </div>
                                    )}
                                  />
                                  <ValidationError
                                    className={`${
                                      (formik_subscriptionBundle.touched
                                        .add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.product_id &&
                                        (
                                          formik_subscriptionBundle.errors
                                            .add_on_plans as any
                                        )?.[planIndex]?.foc?.item_details?.[
                                          index
                                        ]?.product_id) ||
                                      ((
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details &&
                                        typeof (
                                          formik_subscriptionBundle.errors
                                            .add_on_plans as any
                                        )?.[planIndex]?.foc?.item_details ===
                                          "string")
                                        ? "block"
                                        : "hidden"
                                    }`}
                                  >
                                    {formik_subscriptionBundle.touched
                                      .add_on_plans?.[planIndex]?.foc
                                      ?.item_details?.[index]?.product_id &&
                                      ((
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.product_id ||
                                        ((
                                          formik_subscriptionBundle.errors
                                            .add_on_plans as any
                                        )?.[planIndex]?.foc?.item_details &&
                                        typeof (
                                          formik_subscriptionBundle.errors
                                            .add_on_plans as any
                                        )?.[planIndex]?.foc?.item_details ===
                                          "string"
                                          ? (
                                              formik_subscriptionBundle.errors
                                                .add_on_plans as any
                                            )?.[planIndex]?.foc?.item_details
                                          : ""))}
                                  </ValidationError>
                                </div>

                                <div>
                                  <label
                                    htmlFor={`FocItemQty_${index}`}
                                    className="text-[#292929] capitalize block mb-2"
                                  >
                                    {index + 1}. Quantity
                                  </label>
                                  <InputNumber
                                    value={group.quantity}
                                    onChange={(e) =>
                                      handleInputChangeFocAddOnItem(
                                        planIndex, // You'll need to have this value available
                                        index, // You'll need to have this value available
                                        "quantity",
                                        e.value || 0,
                                      )
                                    }
                                    className={`w-full ${
                                      formik_subscriptionBundle.touched
                                        .add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.quantity &&
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.quantity
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                  />
                                  <ValidationError
                                    className={`${
                                      formik_subscriptionBundle.touched
                                        .add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.quantity &&
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.quantity
                                        ? "block"
                                        : "hidden"
                                    }`}
                                  >
                                    {
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.quantity
                                    }
                                  </ValidationError>
                                </div>
                                <div>
                                  <label
                                    htmlFor={`FocItemQty_${index}`}
                                    className="text-[#292929] capitalize block mb-2"
                                  >
                                    Free Gift Group Number
                                  </label>
                                  <InputNumber
                                    value={group.freegift_no ?? 0}
                                    onChange={(e) =>
                                      handleInputChangeFocAddOnItem(
                                        planIndex, // You'll need to have this value available
                                        index, // You'll need to have this value available
                                        "freegift_no",
                                        e.value || 0,
                                      )
                                    }
                                    className={`w-full ${
                                      formik_subscriptionBundle.touched
                                        .add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.freegift_no &&
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.freegift_no
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                  />
                                  <ValidationError
                                    className={`${
                                      formik_subscriptionBundle.touched
                                        .add_on_plans?.[planIndex]?.foc
                                        ?.item_details?.[index]?.freegift_no &&
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.freegift_no
                                        ? "block"
                                        : "hidden"
                                    }`}
                                  >
                                    {
                                      (
                                        formik_subscriptionBundle.errors
                                          .add_on_plans as any
                                      )?.[planIndex]?.foc?.item_details?.[index]
                                        ?.freegift_no
                                    }
                                  </ValidationError>
                                </div>
                                <div>
                                  <Checkbox
                                    inputId={`optionalCheckbox_${planIndex}_${index}`}
                                    checked={
                                      focAddOnItemDetails[planIndex]?.[index]
                                        ?.optional === 1
                                    }
                                    onChange={(e) => {
                                      const updatedFocAddOnItemDetails = {
                                        ...focAddOnItemDetails,
                                      };
                                      if (
                                        !updatedFocAddOnItemDetails[planIndex][
                                          index
                                        ]
                                      ) {
                                        updatedFocAddOnItemDetails[planIndex][
                                          index
                                        ] = {
                                          sub_category_id: 0,
                                          product_id: null,
                                          quantity: 0,
                                          optional: 0,
                                          freegift_no: 0,
                                        };
                                      }
                                      updatedFocAddOnItemDetails[planIndex][
                                        index
                                      ].optional = e.checked ? 1 : 0;

                                      setFocAddOnItemDetails(
                                        updatedFocAddOnItemDetails,
                                      );

                                      formik_subscriptionBundle.setFieldValue(
                                        `add_on_plans[${planIndex}].foc.item_details[${index}].optional`,
                                        e.checked ? 1 : 0,
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor={`optionalCheckbox_${planIndex}_${index}`}
                                    className="ml-2"
                                  >
                                    Optional
                                  </label>
                                </div>

                                <div className={`mt-auto`}>
                                  <button
                                    onClick={() =>
                                      removeFocAddOnItemGroup(planIndex, index)
                                    }
                                    type="button"
                                    className="rounded-[10px] bg-red-500 text-white py-[9px] px-[24px] w-fit"
                                  >
                                    {focAddOnItemDetails[planIndex]?.length ===
                                    1
                                      ? "Disable Free Gift"
                                      : `Remove Free Gift`}
                                  </button>
                                </div>
                              </div>
                            ),
                          )}
                          <button
                            type="button"
                            onClick={() => addFocAddOnItemGroup(planIndex)}
                            className="rounded-[10px] bg-[#82d7d2] py-[9px] px-[24px]"
                          >
                            {focAddOnItemDetails[planIndex]?.length > 0
                              ? "Add Another Free Gift"
                              : "Enable Free Gift"}
                          </button>
                        </Fieldset>
                      </div>
                    </div>
                  )}
                  <button
                    onClick={() => removeOptionGroup(planIndex)}
                    type="button"
                    className="mt-5 block ml-auto rounded-[10px] bg-red-500 text-white py-[9px] px-[24px] w-fit"
                  >
                    {formik_subscriptionBundle.values.add_on_plans.length === 1
                      ? "Disable Add On Option"
                      : `Remove Add On Option ${planIndex + 1}`}
                  </button>
                </div>
              ),
            )}
            <button
              type="button"
              onClick={addOptionGroup}
              className="rounded-[10px] bg-[#82d7d2] py-[9px] px-[24px]"
            >
              {formik_subscriptionBundle.values?.add_on_plans?.length > 0
                ? "Add Another Add On Option"
                : "Enable Add On Option"}
            </button>
          </Fieldset>
        </div>
        <div className="flex justify-end">
          <AcceptButton
            label={formik_subscriptionBundle.values.id > 0 ? "Update" : "Save"}
            loading={productLoading}
            className="mt-[20px]"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default BundleComponent;
