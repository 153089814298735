import { format } from "date-fns";
import { Checkbox } from "primereact/checkbox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import CartItems from "../../components/cartItems";
import useApiData from "../../hooks/useApiData";
import useDeliveryDateRequired from "../../hooks/useDeliveryDateRequired";
import { useAppSelector } from "../../hooks/useStore";
import { HeaderType } from "../../interfaces";
import {
  getAdditionalInfo,
  setAdditionalInfo,
  setBillingInfo,
  setCart,
  setCartItems,
  setOrderInfo,
  clearError,
} from "../../redux/slices/orderSlice";
import { post } from "../../utils/Api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

export const KnobProgress = () => {
  return (
    <img
      src={require("../../assets/images/chart-step-4.png")}
      alt="Chart step 4"
      className="w-[55px] h-[55px]"
    />
  );
};

const OrderDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    billingInfo,
    orderSettings,
    additionalInfo,
    cart,
    isError,
    hasPromotionCode,
  } = useAppSelector((state) => state.order);

  const [order, setOrder] = useState({ id: 0, payment_intent_id: "" });
  const [tncConsent, setTNCConsent] = useState(false);
  const [promotionCode, setPromotionCode] = useState("");
  const [isPromotionCodeApplied, setIsPromotionCodeApplied] = useState(false);
  const deliveryDateRequired = useDeliveryDateRequired();

  const { data, isLoading, error } = useApiData(`get_setting`);
  const setting = data as any;

  const openTNC = () => {
    window.open(setting?.tnc, "_blank");
  };

  const openPrivacyPolicy = () => {
    window.open(setting?.privacy_policy, "_blank");
  };

  const [loading, setLoading] = useState(false);

  const createOrder = async () => {
    if (!tncConsent) {
      toast.error(
        "You must agree to the Terms & Condition and Privacy Policy before creating an order.",
      );
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      if (additionalInfo?.order_id) {
        formData.append(`order_id`, additionalInfo.order_id);
      }
      if (billingInfo?.shared_agent) {
        formData.append(`shared_agent_id`, billingInfo.shared_agent);
      }
      if (billingInfo?.customer_id) {
        formData.append(`customer_id`, billingInfo.customer_id);
      }
      if (billingInfo?.venue) {
        formData.append(`venue_id`, billingInfo.venue);
      }
      formData.append(`country_code`, billingInfo?.country_code);
      formData.append(`mobile`, billingInfo?.mobile);
      formData.append(`email`, billingInfo?.email);
      formData.append(`first_name`, billingInfo?.first_name);
      formData.append(`last_name`, billingInfo?.last_name);
      formData.append(`address[country_code]`, billingInfo?.country_code);
      formData.append(`address[mobile]`, billingInfo?.mobile);
      formData.append(`address[email]`, billingInfo?.email);
      formData.append(`address[first_name]`, billingInfo?.first_name);
      formData.append(`address[last_name]`, billingInfo?.last_name);
      formData.append(`address[postal_code]`, billingInfo?.postal_code);
      formData.append(`address[street]`, billingInfo?.street);
      formData.append(`address[unit_no]`, billingInfo?.unit_no);
      formData.append(
        `billing_address[country_code]`,
        billingInfo?.shipping_country_code,
      );
      formData.append(`billing_address[mobile]`, billingInfo?.shipping_mobile);
      // formData.append(`billing_address[email]`, billingInfo?.email);
      formData.append(
        `billing_address[first_name]`,
        billingInfo?.shipping_first_name,
      );
      formData.append(
        `billing_address[last_name]`,
        billingInfo?.shipping_last_name,
      );
      formData.append(
        `billing_address[postal_code]`,
        billingInfo?.shipping_postal_code,
      );
      formData.append(`billing_address[street]`, billingInfo?.shipping_street);
      formData.append(
        `billing_address[unit_no]`,
        billingInfo?.shipping_unit_no,
      );
      formData.append(
        `same_as_address`,
        billingInfo?.same_as_billing_address ? "1" : "0",
      );
      if (billingInfo?.address_id) {
        formData.append(`address_id`, billingInfo.address_id);
      }
      cart.map(({ cartItems, orderInfo }: any, cartIndex: number) => {
        cartItems.map((item: any, productIndex: number) => {
          if (orderInfo?.subscriptionInfo?.subscriptionId) {
            formData.append(
              `items[${cartIndex}][subscription_option_id]`,
              orderInfo.subscriptionInfo.subscriptionId,
            );
          }
          formData.append(
            `items[${cartIndex}][purchase_option_id]`,
            orderInfo?.purchaseOption[0]?.id,
          );
          if (orderInfo?.subscriptionInfo?.selfCollect) {
            formData.append(
              `items[${cartIndex}][self_collected]`,
              orderInfo.subscriptionInfo.selfCollect ? "1" : "0",
            );
          }
          if (additionalInfo?.deliveryDate) {
            formData.append(
              `items[${cartIndex}][delivery_date]`,
              format(additionalInfo.deliveryDate, "yyyy/MM/dd"),
            );
          }
          formData.append(
            `items[${cartIndex}][products][${productIndex}][product_id]`,
            item?.id,
          );
          formData.append(
            `items[${cartIndex}][products][${productIndex}][delivery_option_id]`,
            item?.delivery_option_id,
          );
          formData.append(
            `items[${cartIndex}][products][${productIndex}][quantity]`,
            item?.quantity,
          );
          if (orderInfo?.purchaseOption[0]?.id === 3) {
            item?.bundle_items?.map((bundle: any, bundleIndex: number) => {
              if (bundle?.id) {
                formData.append(
                  `items[${cartIndex}][products][${productIndex}][products][${bundleIndex}][product_id]`,
                  bundle?.id,
                );
              }
              if (bundle?.quantity) {
                formData.append(
                  `items[${cartIndex}][products][${productIndex}][products][${bundleIndex}][quantity]`,
                  bundle?.quantity, // BE will calculate quantity
                  // (bundle?.quantity * item?.quantity).toString(),
                );
              }
              if (bundle?.type) {
                formData.append(
                  `items[${cartIndex}][products][${productIndex}][products][${bundleIndex}][type]`,
                  bundle?.type,
                );
              }
              if (bundle?.addon_id) {
                formData.append(
                  `items[${cartIndex}][products][${productIndex}][products][${bundleIndex}][addon_id]`,
                  bundle?.addon_id,
                );
              }
            });
          }
        });
        orderInfo?.freeGifts?.map((item: any, giftIndex: number) => {
          formData.append(
            `items[${cartIndex}][free_gifts][${giftIndex}][product_id]`,
            item?.id,
          );
          formData.append(
            `items[${cartIndex}][free_gifts][${giftIndex}][quantity]`,
            "1",
          );
        });
      });

      if (isPromotionCodeApplied && promotionCode !== "") {
        formData.append(`promotion_code`, promotionCode);
      }
      const response = await post(
        additionalInfo?.order_id
          ? `order/update_order`
          : `order/create_orderV2`,
        formData,
      );
      setLoading(false);
      if (response?.success) {
        setOrder(response.data);
        dispatch(setCartItems([]));
        dispatch(setOrderInfo({}));
        dispatch(setCart([]));
        dispatch(setBillingInfo({}));
        dispatch(setAdditionalInfo({}));
        navigate("/order-details", {
          state: {
            order_id: response?.data?.id,
            doNotCallPusher: false,
            openQRdialog: true,
          },
        });
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error: any) {
      setLoading(false);
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (isError) {
      toast.error(isError);
      if (isError === "Invalid promotion code.") {
        setPromotionCode("");
        setIsPromotionCodeApplied(false);
        dispatch(getAdditionalInfo());
      }
      dispatch(clearError());
    }
    if (!hasPromotionCode && promotionCode !== "") {
      setPromotionCode("");
      setIsPromotionCodeApplied(false);
    }
  }, [isError, hasPromotionCode, dispatch]);

  const footerButton = (
    <button
      className="w-full h-[70px] bg-[#DA5E18] text-[20px] text-white flex flex-row justify-center items-center flex-nowrap"
      onClick={createOrder}
      disabled={loading}
    >
      <img
        src={require("../../assets/images/icon-currency-white.png")}
        alt="Currency icon"
        className="w-[24px] h-[24px] mr-[5px]"
      />
      {loading ? "Loading..." : "Make Payment"}
    </button>
  );

  return (
    <div className="bg-[#F8F8F9]">
      <Layout
        headerType={HeaderType.back}
        pageTitle="Cart"
        footerButton={footerButton}
        stickyHeaderFooter={true}
        rightSideIcon={<KnobProgress />}
      >
        <label className="flex items-center min-h-[48px] text-[#DA5E18] justify-between bg-[#343426] rounded-[7px] mb-[10px] py-[5px] pl-[16px] pr-[5px]">
          Customer Info
        </label>
        <div
          className={`bg-[#FFFFFF] rounded-lg border-[#E3E3E3] border-[0.8px] mt-[11px] mb-[17px] py-2.5 px-[15px] shadow-[2px_4px_4px_0_rgba(0,0,0,0.06)] text-sm max-w-full overflow-hidden`}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center pl-[6px]">
              <img
                src={require("../../assets/images/icon-profile.png")}
                alt="Menu icon"
                className="w-[23px] h-[23px] mr-[5px] cursor-pointer"
              />
              <label className="text-[#3E3E3E] text-base">
                {billingInfo?.first_name} {billingInfo?.last_name}
              </label>
            </div>
          </div>
          <div className="mt-3">
            <div className="flex items-center pl-[6px] bg-[#F7F7F7] rounded-[11px]">
              <img
                src={require("../../assets/images/order/phone-icon.png")}
                alt="Menu icon"
                className="w-[23px] h-[23px] mr-[5px] cursor-pointer"
              />
              <label className="text-[#3E3E3E] text-base break-all">
                {billingInfo?.country_code} {billingInfo?.mobile}
              </label>
            </div>
            <div className="flex items-center mt-3 pl-[6px]">
              <img
                src={require("../../assets/images/order/mail-icon.png")}
                alt="Menu icon"
                className="w-[23px] h-[23px] mr-[5px] cursor-pointer"
              />
              <label className="text-[#3E3E3E] text-base break-all">
                {billingInfo?.email}
              </label>
            </div>
          </div>
        </div>
        <label className="flex items-center min-h-[48px] text-[#DA5E18] justify-between bg-[#343426] rounded-[7px] mb-[10px] py-[5px] pl-[16px] pr-[5px]">
          Billing Address
        </label>
        <div
          className={`bg-[#FFFFFF] rounded-lg border-[#E3E3E3] border-[0.8px] mt-[11px] mb-[17px] py-3.5 px-[21px] shadow-[2px_4px_4px_0_rgba(0,0,0,0.06)] text-sm max-w-full overflow-hidden`}
        >
          <div>
            <label className="text-[#3E3E3E] text-base">
              {billingInfo?.street} <br></br> {billingInfo?.unit_no}
            </label>
          </div>
          <div>
            <label className="text-[#3E3E3E] text-base break-all">
              {billingInfo?.postal_code?.length === 6
                ? `SINGAPORE ${billingInfo?.postal_code}`
                : billingInfo?.postal_code}
            </label>
          </div>
        </div>
        <div className={`${deliveryDateRequired ? "block" : "hidden"}`}>
          <label className="pl-[23px] text-[#DA5E18]">Shipping Address</label>
          <div
            className={`bg-[#FFFFFF] rounded-lg border-[#E3E3E3] border-[0.8px] mt-[11px] mb-[17px] py-3.5 px-[21px] shadow-[2px_4px_4px_0_rgba(0,0,0,0.06)] text-sm max-w-full overflow-hidden`}
          >
            <div>
              <label className="text-[#3E3E3E] text-base">
                {billingInfo?.shipping_street} <br></br>{" "}
                {billingInfo?.shipping_unit_no}
              </label>
            </div>
            <div>
              <label className="text-[#3E3E3E] text-base break-all">
                {billingInfo?.shipping_postal_code?.length === 6
                  ? `SINGAPORE ${billingInfo?.shipping_postal_code}`
                  : billingInfo?.shipping_postal_code}
              </label>
            </div>
          </div>
        </div>
        {billingInfo?.venue && (
          <div>
            <label className="flex items-center min-h-[48px] text-[#DA5E18] justify-between bg-[#343426] rounded-[7px] mb-[10px] py-[5px] pl-[16px] pr-[5px]">
              Venue
            </label>
            <div
              className={`bg-[#FFFFFF] rounded-lg border-[#E3E3E3] border-[0.8px] mt-[11px] mb-[17px] py-3.5 px-[21px] shadow-[2px_4px_4px_0_rgba(0,0,0,0.06)] text-sm max-w-full overflow-hidden`}
            >
              <div>
                <label className="text-[#3E3E3E] text-base">
                  {additionalInfo?.venue[0]?.name}
                </label>
              </div>
            </div>
          </div>
        )}
        <CartItems />
        <div className="flex items-center justify-between bg-[#343426] rounded-[7px] mb-[10px] py-[5px] pl-[16px] pr-[5px]">
          <label className="text-[#DA5E18] text-[16px]">Promotion Code</label>
          <Button
            link
            className={`p-[5px] bg-white`}
            onClick={() => {
              if (!isPromotionCodeApplied && promotionCode === "") {
                return;
              }
              if (isPromotionCodeApplied) {
                setPromotionCode("");
                dispatch(getAdditionalInfo());
              } else {
                dispatch(getAdditionalInfo({ promotionCode: promotionCode }));
              }
              setIsPromotionCodeApplied(!isPromotionCodeApplied);
            }}
          >
            {isPromotionCodeApplied ? "Reset" : "Apply"}
          </Button>
        </div>
        <InputText
          className="w-full mb-[18px]"
          disabled={isPromotionCodeApplied}
          name="promotion_discount"
          placeholder="Promotion Discount"
          value={promotionCode}
          onChange={(e) => setPromotionCode(e.target.value)}
        ></InputText>
        {deliveryDateRequired && (
          <div className="bg-[#fff] w-full h-[44px]">
            <div className="flex items-center justify-center h-full">
              <img
                src={require("../../assets/images/order/icon-calendar-black.png")}
                alt="Calendar Icon"
                className={`w-[24px] h-[24px] mr-1.5`}
              />
              {additionalInfo?.deliveryDate && (
                <label className="text-[#434343] text-[14px]">
                  Estimated Shipment Date:{" "}
                  {format(additionalInfo.deliveryDate, "dd MMM yyyy")}
                </label>
              )}
              {!additionalInfo?.deliveryDate && (
                <label className="text-[#434343] text-[14px]">
                  Estimated Shipment Date: --Select--
                </label>
              )}
            </div>
          </div>
        )}
        <div className="bg-[#F7F7F7] py-4 px-4">
          <div className="flex justify-between">
            <div className="text-[#434343] text-[14px]">Subtotal</div>
            <div className="text-[#DA5E18] text-[14px]">
              {" "}
              {additionalInfo?.sum_formatted}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-[#434343] text-[14px]">Discount</div>
            <div className="text-[#DA5E18] text-[14px]">
              {"-"}
              {additionalInfo?.discount_formatted}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-[#434343] text-[14px]">Promotion Discount</div>
            <div className="text-[#DA5E18] text-[14px]">
              {"-"}
              {additionalInfo?.promotion_discount_formatted}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-[#434343] text-[14px]">Delivery Fee</div>
            <div className="text-[#DA5E18] text-[14px]">
              {" "}
              {additionalInfo?.delivery_formatted}
            </div>
          </div>
          {orderSettings?.gst !== 0 ? (
            <div className="flex justify-between">
              <div className="text-[#434343] text-[14px]">
                GST ({orderSettings?.gst}%)
              </div>
              <div className="text-[#DA5E18] text-[14px]">
                {additionalInfo?.tax_formatted}
              </div>
            </div>
          ) : null}
          <div className="flex justify-between mt-2">
            <div className="text-[#434343] font-bold text-[14px]">Total</div>
            <div className="text-[#DA5E18] text-[20px]">
              {" "}
              {additionalInfo?.total_formatted}
            </div>
          </div>
        </div>
        <div>
          <div className="p-field-checkbox flex my-[15px]">
            <Checkbox
              className="mr-[10px]"
              inputId="tncConsent"
              checked={tncConsent}
              onChange={() => setTNCConsent(!tncConsent)}
            />
            <label
              className="md:text-white"
              htmlFor="tncConsent"
              onClick={() => {
                setTNCConsent(!tncConsent);
              }}
            >
              I agree to the{" "}
              <span
                onClick={() => {
                  setTNCConsent(!tncConsent);
                  openTNC();
                }}
                className="cursor-pointer text-[#4469F3] hover:text-[#3650ad] underline"
              >
                {" "}
                Terms & Conditions{" "}
              </span>
              and{" "}
              <span
                onClick={() => {
                  setTNCConsent(!tncConsent);
                  openPrivacyPolicy();
                }}
                className="cursor-pointer text-[#4469F3] hover:text-[#3650ad] underline"
              >
                Privacy Policy
              </span>
              .
            </label>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default OrderDetail;
